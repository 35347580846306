import { featureSectionSchema } from '@ecomm/shared-sections'
import { graphql, useStaticQuery } from 'gatsby'

export const useOdmonPDPQuery = () => {
  const data = useStaticQuery(graphql`
    query {
      odmonProtectionSection: contentfulFeatureSection(contentful_id: { eq: "3PeFHW4knSiVoTq3V00BVJ" }) {
        # badge
        contentful_id
        body {
          raw
          references {
            ... on ContentfulButton {
              contentful_id
              __typename
              text
              url
              type
            }
          }
        }
        media {
          contentful_id
          __typename
          mimeType
          file {
            contentType
            url
          }
          title
          gatsbyImageData(layout: FULL_WIDTH)
          description
        }
        type
        __typename
      }
    }
  `)

  return [featureSectionSchema.parse(data.odmonProtectionSection)]
}
