import {
  OLYMPUS_SKU,
  SCOUT_SKU,
  SHIELD_SKU,
  SIMPLICAM_SKU
} from '@ecomm/data-constants'
import { Text } from '@ecomm/ss-react-components'
import type { CartLineItemProps } from '@ecomm/ss-react-components/CartLineItem'
import { params } from '@ecomm/tracking'
import { isNotEmpty, prop } from '@simplisafe/ewok'
import { type Locale, localStorage } from '@simplisafe/ewok'
import { safeProp } from '@simplisafe/monda'
import type {
  ImmutableCart,
  LineItem
} from '@simplisafe/ss-ecomm-data/commercetools/cart'
import { Link, navigate } from 'gatsby'
import { getImage } from 'gatsby-plugin-image'
import { BgImage } from 'gbimage-bridge'
import { Maybe } from 'monet'
import applySpec from 'ramda/src/applySpec'
import equals from 'ramda/src/equals'
import ifElse from 'ramda/src/ifElse'
import length from 'ramda/src/length'
import map from 'ramda/src/map'
import pipe from 'ramda/src/pipe'
import React from 'react'

import type {
  ContentfulAsset,
  ContentfulCartDetails,
  ContentfulProductCardAccessories
} from '../../../graphql'
import {
  type CoreComponentsData,
  renderCoreComponentsCartMessage,
  renderCoreComponentsNotInStockMsg
} from '../../commercetools/outOfStock'
import { formatDisplayPrice } from '../../commercetools/price'
import type { ContentfulComponent } from '../../componentMappings'
import { outdoorCamCrm } from '../../hooks/useUtmContent'
import type { CartPageContext } from '../../templates/CartPage'
import { leadingSlashIt, toButton } from '../../util/helper'
import { renderComponentFromData } from '../../util/render'
import { renderPageComponents } from '../Page/helpers'
import { type CartDetailsProps, CartContent } from './CartContent'
import { OdmonPreLaunchCartContent } from './OdmonPreLaunchCartContent'
const { get, set } = localStorage

const renderCreditCardLogoImg = (data: Partial<ContentfulCartDetails>) => {
  const creditCardLogo = prop('creditCardLogo', data)
  return map(toFormImg)(creditCardLogo as readonly ContentfulAsset[])
}
const toFormImg = (data: Partial<ContentfulCartDetails>) => (
  <BgImage
    alt={data?.description || ''}
    critical={true}
    image={{
      ...getImage(data),
      placeholder: undefined
    }}
    key={data?.id}
    style={{
      height: '25px',
      width: '45px'
    }}
  />
)
const isShieldWarningOnLocalStorage = () =>
  equals(get(params.content), outdoorCamCrm)

// TODO this should be exported and unit tested
// TODO switch this from applySpec to transformObject
export const toContentfulCartDetails = (
  data: Partial<ContentfulCartDetails>,
  shieldIsInCart: boolean,
  pageContext: CartPageContext
) => {
  const standaloneWithoutSystemMessage = (
    <p>There is no home security system in your cart.</p>
  )
  const shieldWithoutSystemMessage = (
    <p>
      Heads up! You need the latest SimpliSafe system to use our outdoor
      security camera.
    </p>
  )

  const withoutSystemMessage =
    shieldIsInCart && !isShieldWarningOnLocalStorage()
      ? shieldWithoutSystemMessage
      : standaloneWithoutSystemMessage

  return applySpec<CartDetailsProps>({
    affirmMessage: () => data?.affirmMessage?.raw,
    belowCartContentsSection: (data: Partial<ContentfulCartDetails>) =>
      data.belowCartContent?.contents &&
      renderPageComponents(pageContext)(data.belowCartContent?.contents),
    checkOutButtonFooter: pipe(prop('checkoutButtonFooter'), toButton),
    checkOutButtonHeader: pipe(prop('checkoutButtonHeader'), toButton),
    creditCardLogo: renderCreditCardLogoImg,
    guaranteeNote: () => data?.guaranteeNote?.raw,
    linkHeader: prop('linkHeader'),
    mobileLink: data =>
      safeProp('linkHeader', data)
        .chain(linkData =>
          safeProp('linkText', linkData).chain(linkText =>
            safeProp('linkUrl', linkData).map(linkUrl => (
              <Link
                key={`mobile-link-${linkText}`}
                to={leadingSlashIt(linkUrl)}
              >
                {linkText}
              </Link>
            ))
          )
        )
        .orUndefined(),
    partnerCaptureForm: data => {
      const components: readonly ContentfulComponent[] = safeProp(
        'partnerCaptureModal',
        data
      ).getOrElse([])
      return components.map((component: ContentfulComponent) =>
        renderComponentFromData(component)
      )
    },
    subTotalContent: () => data?.subtotal?.raw || '',
    titleHeader: prop('titleHeader'),
    withoutSystemMessage: () => withoutSystemMessage
  })(data)
}

// checks if only refurbished system is in cart,
// if any other package is in cart, it returns false
export const onlyRefurbishedSystemIsInCart = (
  items: readonly CartLineItemProps[]
): boolean => {
  // return an array of all systems in cart
  const allSystems = items.filter(
    item => safeProp('subItems', item).getOrElse([]).length > 0
  )
  // returns an array of all refurbished systems in cart
  const refurbishedSystems = allSystems.filter(item =>
    safeProp('sku', item).getOrElse('').includes('ss3-refurbished')
  )
  return (
    refurbishedSystems.length > 0 &&
    refurbishedSystems.length === allSystems.length
  )
}

export const toCoreComponentsOosProps = (
  coreComponentsNotInStockList: readonly CoreComponentsData[],
  systemInCart: boolean,
  items: readonly CartLineItemProps[]
) => {
  const showCoreComponentsMessage =
    coreComponentsNotInStockList.length > 0 &&
    systemInCart &&
    !onlyRefurbishedSystemIsInCart(items)
  return showCoreComponentsMessage
    ? {
        coreComponentNotInStock: showCoreComponentsMessage,
        coreComponentShipEstimate: renderCoreComponentsNotInStockMsg(
          coreComponentsNotInStockList,
          false
        ),
        coreComponentsShipDelayText: renderCoreComponentsCartMessage(
          coreComponentsNotInStockList
        )
      }
    : { coreComponentNotInStock: false }
}

export const isPackage = (lineItem: LineItem): boolean =>
  prop('productType', lineItem) === 'package_parent'

export const isCartQualifiedFreeShipping = (cart: ImmutableCart): boolean =>
  safeProp('lineItems', cart)
    .map(lineItems => lineItems.some(isPackage))
    .orJust(false)

export const isCartQualifiedFreeShippingUS = (cart: ImmutableCart): boolean =>
  isCartQualifiedFreeShipping(cart)

export const isCartQualifiedFreeShippingUK = (cart: ImmutableCart): boolean =>
  isCartQualifiedFreeShipping(cart) || prop('totalPrice', cart) > 50

export const getFreeShippingItem = (
  shippingText: string,
  cart: ImmutableCart,
  siteLocale: string
): CartLineItemProps => {
  const hasFreeShippingText = ifElse(
    equals('en-GB'),
    () => isCartQualifiedFreeShippingUK(cart),
    () => isCartQualifiedFreeShippingUS(cart)
  )

  const freeShippingItem: CartLineItemProps = {
    itemDescriptionContent:
      siteLocale === 'en-US' ? (
        <p className="mb-1">
          You may select a different shipping method at checkout.
        </p>
      ) : undefined,
    itemName: shippingText,
    price: formatDisplayPrice(0).orUndefined(),
    isHighlightedLineItem: false,
    isFreeShippingItem: true
  }

  return hasFreeShippingText(siteLocale) && freeShippingItem
}

export const renderCart = (locale: Locale, prop: CartDetailsProps) => {
  return locale === 'en-US' ? (
    <OdmonPreLaunchCartContent {...prop} />
  ) : (
    <CartContent {...prop} />
  )
}

export const shouldShowMonitoringPlanWidget =
  (monitoringSku: string) =>
  (cart: ImmutableCart): boolean => {
    const hasLineItems = !!length(cart.lineItems)
    const cartHasSystem = !!cart.isThereAnySecurity
    const cartHasMonitoringPlan = safeProp('lineItems', cart)
      .map(lineItems => lineItems.some(item => item.sku === monitoringSku))
      .orJust(false)

    return hasLineItems && cartHasSystem && !cartHasMonitoringPlan
  }

/**
 * Get quantity in cart by sku
 */
export const getQuantityInCart =
  (lineItems: readonly LineItem[]) => (sku: string) =>
    lineItems
      .filter(
        lineItem =>
          safeProp('sku', lineItem).getOrElse('').toUpperCase() === sku
      )
      .reduce((prev, cur) => prev + cur.quantity, 0)

/**
 * Find the maximum number of a particular product allowed in a cart by SKU.
 * @param sku The product SKU
 * @param productMaxQuantity Product configuration data
 */
export const getMaxQuantityBySku = (
  sku: string,
  productMaxQuantity?: readonly (ContentfulProductCardAccessories | null)[]
): number => {
  const product = productMaxQuantity
    .filter(product => product)
    .find(product => prop('productId', product) === sku)

  return Maybe.fromUndefined(product)
    .chain(safeProp('maximumQuantity'))
    .orJust(0)
}

export const getShieldMaxQuantity = (
  productMaxQuantity: readonly (ContentfulProductCardAccessories | null)[]
): number => getMaxQuantityBySku(SHIELD_SKU, productMaxQuantity)

/** Filter for Products from lineItmes that do have cameras
 * outdoor camera: CMOB1, outdoor camera 2: CM021-01DWW, indoor camera: SSCM2, video doorbell: SSDB3
 */
// TODO: set tags for sensors with cameras in Commercetools, remove hardcoded filters
const sensorWithCamera = [SHIELD_SKU, OLYMPUS_SKU, 'SSCM2', 'SSDB3']
export const cameraSensorQuantityInCart = (lineItems: readonly LineItem[]) => {
  // TODO: product quantity limit should be added in product data
  return lineItems
    .map(quantity => quantity)
    .filter(product => sensorWithCamera.includes(prop('sku', product)))
    .reduce((prev, cur) => prev + cur.quantity, 0)
}

export const productIsInCart = (
  items: readonly CartLineItemProps[],
  productSku: string
) => {
  return isNotEmpty(
    items.filter(
      item => safeProp('sku', item).getOrElse('').toUpperCase() === productSku
    )
  )
}

const isInteractiveMonitoringInCart = (
  items: readonly CartLineItemProps[],
  siteLocale: string
) =>
  productIsInCart(
    items,
    siteLocale === 'en-US' ? 'SSEDSM2__4867366' : 'SSEDSM2_GB__5229044'
  )

// Sensors with cameras warning message
export const renderSensorWarningMessage = (
  productMaxQuantity: readonly (ContentfulProductCardAccessories | null)[],
  cameraSensorQuantity: number,
  shieldQuantity: number,
  items: readonly CartLineItemProps[],
  siteLocale: string,
  selfMonitoringInCart: boolean
) => {
  const interactiveMonitoringInCart: boolean = isInteractiveMonitoringInCart(
    items,
    siteLocale
  )
  const maxCameraSensors = 10
  const maxShieldQuantity: number = getShieldMaxQuantity(productMaxQuantity)
  const cameraSensorLimit: number = interactiveMonitoringInCart
    ? maxCameraSensors
    : 5
  const showShieldWarning = shieldQuantity > maxShieldQuantity ? true : false
  const showCameraSensorWarning: boolean =
    (interactiveMonitoringInCart || selfMonitoringInCart) &&
    cameraSensorQuantity > cameraSensorLimit

  return showShieldWarning || showCameraSensorWarning
    ? [
        showShieldWarning && (
          <Text
            key={'outdoor-camera-warning'}
          >{`Max number of outdoor security cameras allowed per user is ${maxShieldQuantity}.`}</Text>
        ),
        showCameraSensorWarning ? (
          selfMonitoringInCart ? (
            <Text key={'self-monitoring-warning'}>
              Heads up! You can only get security recordings on{' '}
              {cameraSensorLimit} cameras. Upgrade to 24/7 professional
              monitoring to get recordings on up to {maxCameraSensors} cameras.
            </Text>
          ) : (
            <Text key={'interactive-monitoring-warning'}>
              Heads up! You can only get security recordings on{' '}
              {cameraSensorLimit} cameras.
            </Text>
          )
        ) : undefined
      ]
    : undefined
}

export const navigateLink = (url: string, parentId: string, sku: string) => {
  Maybe.fromFalsy(url === '/change-monitoring').forEach(() =>
    set('parentId', parentId)
  )
  navigate(url, { state: { packageSku: sku } })
}

/**
 ** Simplicam
 */

export const getSimplicamMaxQuantity = (
  productMaxQuantity: readonly (ContentfulProductCardAccessories | null)[]
): number => getMaxQuantityBySku(SIMPLICAM_SKU, productMaxQuantity)

/**
 * * Scout
 */

// Doing this to show the error message if the customer has more than four cameras
export const getScoutMaxQuantity = (
  productMaxQuantity: readonly (ContentfulProductCardAccessories | null)[]
): number => getMaxQuantityBySku(SCOUT_SKU, productMaxQuantity)

/**
 * Olympus: Doing this to show the error message if the customer has more than four Olympus cameras
 */
export const getOlympusMaxQuantity = (
  productMaxQuantity: readonly (ContentfulProductCardAccessories | null)[]
): number => getMaxQuantityBySku(OLYMPUS_SKU, productMaxQuantity)

/**
 * Show Scout warning message in the cart if the cart
 * * Includes the Scout OR shield
 * * AND the cart does not contain a security system
 */

export const renderScoutWarningMessage = (
  items: readonly LineItem[],
  cartIncludesAnySecuritySystem: boolean
) => {
  const cartIncludesScout = getQuantityInCart(items)(SCOUT_SKU) > 0
  const cartIncludesShield = getQuantityInCart(items)(SHIELD_SKU) > 0

  return (
    (cartIncludesScout || cartIncludesShield) && !cartIncludesAnySecuritySystem
  )
}
