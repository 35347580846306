import {
  AffirmMonthlyPricingBanner,
  GuaranteeBanner
} from '@ecomm/shared-components'
import {
  odmonPreLaunchRenameString,
  useAffirmAPRExperiment
} from '@ecomm/shared-hooks'
import { useOdmonExperience } from '@ecomm/shared-ninetailed-odmon'
import type { ExperienceConfiguration } from '@ninetailed/experience.js-shared'
import { exists, window } from 'browser-monads-ts'
import { pipe } from 'fp-ts/lib/function'
import * as O from 'fp-ts/lib/Option'
import type React from 'react'

import type { Response as PackagePriceProps } from '../../components/Package/hooks/usePackagePrice'
import type {
  PackageProduct,
  PackageType
} from '../../components/Package/schema'
import { PersonalizePackageAddOnsButton } from '../../components/PersonalizePackage/PersonalizePackageAddOnsButton'

export type Props = {
  readonly regularPrice: string
  readonly discountAmount: O.Option<string>
  readonly discountText: string
  readonly packageName: string
  readonly packageProducts: readonly PackageProduct[]
  readonly packageType: PackageType
  readonly sku: string
  readonly extrasPrice: string
  readonly affirmFullDiscountedPrice: number
  readonly discountedPrice: O.Option<string>
  readonly error: boolean
  readonly setError: React.Dispatch<React.SetStateAction<boolean>>
  readonly monthlyPrices: PackagePriceProps<string>
  readonly affirmMappedExperiences?: readonly ExperienceConfiguration[]
}

export function PersonalizePackageAddOnsVariation({
  discountedPrice,
  discountAmount,
  discountText,
  affirmFullDiscountedPrice,
  error,
  extrasPrice,
  packageName,
  packageProducts,
  packageType,
  regularPrice,
  setError,
  sku,
  monthlyPrices,
  affirmMappedExperiences = []
}: Props) {
  /* begin ODMON pre-launch test */
  const isODMONVariant = useOdmonExperience().isVariant
  const modifiedDiscountText = isODMONVariant
    ? odmonPreLaunchRenameString(discountText)
    : discountText
  /* end ODMON pre-launch test */

  const isAffirmExperiment = useAffirmAPRExperiment(affirmMappedExperiences)

  const isBrowser = exists(window)
  const affirmClient = isBrowser ? window.affirm : null

  return (
    <>
      <div
        className="grid grid-cols-1 lg:grid-cols-5"
        data-component="PersonalizePackageAddOnsVariation"
      >
        <section
          className="order-2 col-span-1 grid gap-1 lg:order-1 lg:col-span-2"
          data-component="PersonalizePackageAddOnsVariationBanners"
        >
          <div className="flex flex-col gap-4">
            <AffirmMonthlyPricingBanner
              affirmClient={affirmClient}
              className="mt-9 max-h-28"
              discountedPrice={affirmFullDiscountedPrice}
              isAffirmExperience={isAffirmExperiment}
            />
            <GuaranteeBanner
              className="max-h-36"
              description="Test out your SimpliSafe system. If you aren't 100% delighted, call us within 60 days for a full refund and let us pay for return shipping."
            />
          </div>
        </section>

        <section className="order-1 col-span-1 grid lg:order-2 lg:col-span-3">
          <div className="ml-0 flex flex-col items-start gap-2 py-4 md:ml-8">
            <div className="border-neutral-medium-100 flex w-full items-center justify-between gap-4 border-0 border-b border-solid">
              <p className="text-lg font-bold">Package Equipment:</p>
              <p>
                <span className="font-semibold">
                  {monthlyPrices.regularPrice}/mo
                </span>{' '}
                or pay in full{' '}
                <span className="font-semibold">{regularPrice}</span>
              </p>
            </div>

            {pipe(
              discountAmount,
              O.map(amount => (
                <div
                  className="border-neutral-medium-100 flex w-full items-center justify-between gap-4 border-0 border-b border-solid"
                  key="discount-text"
                >
                  <p className="text-sale text-lg font-bold">
                    {modifiedDiscountText}
                  </p>
                  <span className="text-sale font-bold">{amount}</span>
                </div>
              )),
              O.toNullable
            )}

            {packageType !== 'Dynamic' && (
              <div className="border-neutral-medium-100 flex w-full items-center justify-between gap-4 border-0 border-b border-solid">
                <p className="text-lg font-bold">Extra Equipment:</p>
                <p>
                  <span className="font-semibold">
                    {monthlyPrices.extrasPrice}/mo
                  </span>{' '}
                  or pay in full{' '}
                  <span className="font-semibold">{extrasPrice}</span>
                </p>
              </div>
            )}

            {pipe(
              discountedPrice,
              O.map(price => (
                <div
                  className="flex w-full items-center justify-between gap-4"
                  key="system-total"
                >
                  <p className="text-xl font-bold">System Total:</p>
                  <p key="system-total">
                    {pipe(
                      monthlyPrices.discountedPrice,
                      O.map(price => (
                        <span
                          className="font-semibold"
                          key="monthly-system-total"
                        >
                          {price}/mo
                        </span>
                      )),
                      O.toNullable
                    )}
                    {` `}
                    or pay in full{' '}
                    <span className="font-semibold">{price}</span>
                  </p>
                </div>
              )),
              O.toNullable
            )}
          </div>
          <PersonalizePackageAddOnsButton
            className="mt-4 w-full md:w-full lg:w-auto"
            error={error}
            packageName={packageName}
            packageProducts={packageProducts}
            packageType={packageType}
            setError={setError}
            sku={sku}
          />
        </section>
      </div>
    </>
  )
}
