import { contentfulImageSchema } from '@ecomm/contentful/components'
import { contentfulRichTextSchema } from '@ecomm/contentful-schemas'
import { iconAndTextSchema } from '@ecomm/shared-components'
import { type TypeOf, z } from '@simplisafe/ewok'

import {
  imageAndTextSchema,
  planFeatureSchema
} from '../MonitoringPlanCard/schema'
import { monitoringPlanModalSchema } from '../MonitoringPlanModal/schema'

const buttonSchema = z.object({
  contentful_id: z.string(),
  __typename: z.literal('ContentfulButton'),
  url: z.string(),
  buttonText: z.string()
})

const referencesSchema = z.union([planFeatureSchema, buttonSchema])

const additionalFeaturesSchema = z.union([
  iconAndTextSchema,
  imageAndTextSchema
])

const modalSchema = z.object({
  __typename: z.literal('ContentfulModal'),
  contentful_id: z.string(),
  description: contentfulRichTextSchema,
  headlineText: z.string(),
  image: contentfulImageSchema
})

export const monitoringCardsSchema = z.object({
  __typename: z.literal('ContentfulMonitoringPlan'),
  sku: z.string(),
  name: z.string(),
  cta: z.string(),
  description: z
    .object({
      raw: z.string(),
      references: z.array(modalSchema).optional()
    })
    .nullish(),
  additionalFeatures: additionalFeaturesSchema.array().nonempty(),
  disclaimer: z.string().optional()
})

export const odmonExpandableMonitoringSchema = z.object({
  __typename: z.literal('ContentfulOdmonMonitoringPlanComparison'),
  headline: z.string().nullish(),
  subheadline: z.string().nullish(),
  monitoringCards: monitoringCardsSchema.array(),
  comparisonTable: z.object({
    raw: z.string(),
    references: referencesSchema.array().optional()
  }),
  comparisonDisclaimer: z.object({
    raw: z.string(),
    references: z.array(monitoringPlanModalSchema).optional()
  }),
  showAddToCartButton: z.boolean().optional().default(true)
})

export type OdmonExpandableMonitoringSchema = TypeOf<
  typeof odmonExpandableMonitoringSchema
>
