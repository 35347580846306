import React, { useState } from 'react'
import { ChevronDown } from '@ecomm/shared-icons'

export type ShowMoreProps = {
  readonly children: JSX.Element | readonly JSX.Element[] | null
  readonly hiddenClasses?: string
}
export function ShowMore({ children, hiddenClasses }: ShowMoreProps) {
  const [isOpen, setIsOpen] = useState(false)
  const onClick = () => setIsOpen(!isOpen)
  const openClass = isOpen ? 'rotate-180' : 'rotate-0'
  return (
    <div data-testid="ShowMoreSection">
      <div className={!isOpen ? hiddenClasses : ''}>{children}</div>
      <button
        className="text-complementary-blue-100 border-none bg-transparent block text-sm hover:no-underline min-[1126px]:text-base cursor-pointer mt-3"
        onClick={onClick}
      >
        {isOpen ? 'Show Less' : 'Show More'}{' '}
        <ChevronDown className={'h-4 w-4 inline-block ' + openClass} />
      </button>
    </div>
  )
}
