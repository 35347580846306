import { contentfulImageSchema } from '@ecomm/contentful/components'
import { contentfulRichTextSchema } from '@ecomm/contentful-schemas'
import { jsonSchema } from '@ecomm/micro-copy/src/lib/jsonSchema'
import {
  expandableMonitoringSchema,
  odmonExpandableMonitoringSchema
} from '@ecomm/monitoring/components'
import { alarmMomentsSchema, iconAndTextSchema } from '@ecomm/shared-components'
import { experimentTemplateSchema } from '@ecomm/shared-ninetailed'
import { appWidgetSchema, featureSectionSchema } from '@ecomm/shared-sections'
import { smallTextSchema } from '@ecomm/shared-sections'
import { type TypeOf, z } from '@simplisafe/ewok'

export const videoSchema = z.object({
  __typename: z.literal('ContentfulVideo'),
  entryTitle: z.string(),
  webmLink: z.string(),
  mp4Link: z.string(),
  posterImage: z.string().nullish(),
  captions: z.string().nullish()
})

export const gridSchema = z.object({
  gridItems: iconAndTextSchema.array().nonempty(),
  __typename: z.literal('ContentfulGrid')
})

const faqSchema = z.object({
  __typename: z.literal('ContentfulFaq'),
  title: z.string(),
  primary: z
    .object({
      question: z.object({
        question: z.string()
      }),
      answer: contentfulRichTextSchema
    })
    .array()
})

const placeholderSchema = z.object({
  __typename: z.literal('ContentfulPlaceholder'),
  label: z.string()
})

const stepperStepSchema = z.object({
  id: z.string(),
  __typename: z.string(),
  title: z.string(),
  description: contentfulRichTextSchema,
  disclaimer: z.string().nullish(),
  image: contentfulImageSchema
})

const stepperSchema = z.object({
  __typename: z.literal('ContentfulStepper'),
  title: z.string(),
  subtitle: z.string().nullish(),
  badge: z.string().nullish(),
  steps: stepperStepSchema.array().nonempty()
})

export const lowerComponentTypes = z.union([
  gridSchema,
  videoSchema,
  appWidgetSchema,
  expandableMonitoringSchema,
  odmonExpandableMonitoringSchema,
  featureSectionSchema,
  alarmMomentsSchema.extend({
    __typename: z.literal('ContentfulAlarmMoments')
  }),
  jsonSchema.extend({ __typename: z.literal('ContentfulJson') }),
  placeholderSchema,
  faqSchema,
  stepperSchema,
  smallTextSchema
])

export const monitoringContentDraftSchema = z.object({
  __typename: z.literal('ContentfulMonitoringPageContent'),
  id: z.string(),
  inside: lowerComponentTypes.array().default([])
})

const experimentTemplateContentSchema = experimentTemplateSchema.extend({
  variants: z.array(monitoringContentDraftSchema).default([])
})

export const monitoringContentSchema = monitoringContentDraftSchema.extend({
  nt_experiences: z
    .array(experimentTemplateContentSchema)
    .optional()
    .default([])
})

export const componentTypes = z.union([
  gridSchema,
  videoSchema,
  appWidgetSchema,
  expandableMonitoringSchema,
  odmonExpandableMonitoringSchema,
  featureSectionSchema,
  alarmMomentsSchema.extend({
    __typename: z.literal('ContentfulAlarmMoments')
  }),
  jsonSchema.extend({ __typename: z.literal('ContentfulJson') }),
  placeholderSchema,
  faqSchema,
  monitoringContentSchema,
  stepperSchema,
  smallTextSchema
])

export const contentMapperSchema = z.object({
  inside: componentTypes.array().default([])
})

export type ComponentTypes = TypeOf<typeof componentTypes>
export type ContentMapperSchema = TypeOf<typeof contentMapperSchema>
export type StepperStepSchema = TypeOf<typeof stepperStepSchema>
export type StepperSchema = TypeOf<typeof stepperSchema>
export type ContentExperienceSchema = TypeOf<typeof monitoringContentSchema>
