import { ContentfulImage } from '@ecomm/contentful/components'
import { ContentfulRichText } from '@ecomm/shared-components'
import classNames from 'classnames'
import React from 'react'

import type { StepperStepSchema } from '../ContentMapper/schema'
import { StepLine } from './StepLine'

export type Props = StepperStepSchema & {
  readonly stepNumber: number
  readonly isLastStep: boolean
}

export function Step({
  title,
  stepNumber,
  description,
  image,
  disclaimer,
  isLastStep
}: Props) {
  return (
    <div className="hidden grid-cols-[560px,90px,1fr] lg:grid">
      <div
        className={classNames('', {
          'order-1': stepNumber % 2 !== 0,
          'order-3': stepNumber % 2 === 0
        })}
      >
        <ContentfulImage {...image} className="bg-transparent" quality={100} />
      </div>

      <StepLine isLastStep={isLastStep} stepNumber={stepNumber} />

      <div
        className={classNames('flex flex-col justify-center', {
          'order-3 ml-6': stepNumber % 2 !== 0,
          'order-1 mr-2': stepNumber % 2 === 0
        })}
      >
        <h3 className="mb-6 text-2xl">{title}</h3>
        <ContentfulRichText raw={description.raw} />
        <p className="text-xs">{disclaimer}</p>
      </div>
    </div>
  )
}
