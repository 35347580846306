import { LoadingSpinner } from '@ecomm/checkout-icons'
import {
  ODMON_247_MONITORING,
  ODMON_OVERNIGHT_MONITORING
} from '@ecomm/data-constants'
import { usePriceContext } from '@ecomm/data-price'
import { getCartId } from '@ecomm/data-storage'
import { useMicroCopy } from '@ecomm/micro-copy'
import { useIsCurrentPromoLoading } from '@ecomm/promotions-hooks'
import { getPartnerCookie } from '@ecomm/shared-cookies'
import { SimpleButton } from '@ecomm/ss-react-components'
import { useTrackAddToCart, useTrackATCPlanSelection } from '@ecomm/tracking'
import { useEnv } from '@ecomm/utils'
import { convertOption } from '@simplisafe/ewok'
import { IOCreateOrSetPartnerAssociationCart } from '@simplisafe/ss-ecomm-data/cart'
import classNames from 'classnames'
import { pipe } from 'fp-ts/lib/function'
import * as O from 'fp-ts/lib/Option'
import { useAtom } from 'jotai'
import {
  type Dispatch,
  type SetStateAction,
  useCallback,
  useState
} from 'react'
import { useDispatch } from 'react-redux'
import { match } from 'ts-pattern'

import { itemQuantityAtom } from '../../atoms/draftCart/itemQuantityAtom'
import {
  type DraftCartMonitoring,
  monitoringAtom
} from '../../atoms/draftCart/monitoringPlanAtom'
import { proSetupAtom } from '../../atoms/package/monitoringProSetup'
import { useProductContext } from '../../contexts/ProductContext'
import { hasMonitoringPlan } from '../DraftCart/helpers'
import { MonitoringPlan } from '../DraftCart/types'
import { usePackagePrice, useReduxPackageSelectors } from '../Package/hooks'
import type { PackageProduct, PackageType } from '../Package/schema'
import { handleAddToCartRedirection } from './handleAddToCartRedirection'
import {
  IOAddBmsToCart,
  IOAddDynamicPrebuiltToCart
} from '@simplisafe/ss-ecomm-data/cart/actions'

export type Props = {
  readonly className?: string
  readonly isOdmonVariant?: boolean
  readonly packageName: string
  readonly packageSku: string
  readonly setError: Dispatch<SetStateAction<boolean>>
  readonly packageType: PackageType
  readonly packageProducts: readonly PackageProduct[]
}

export const getMonitoringAddToCartData = ({
  monitoring
}: {
  readonly monitoring: DraftCartMonitoring
}) => {
  switch (monitoring.type) {
    case MonitoringPlan.interactive:
      return {
        name: 'Fast Protect™ Monitoring',
        quantity: 1,
        sku: monitoring.plan
      }
    case MonitoringPlan.odmonOvernight:
      return {
        name: 'Pro Monitoring',
        quantity: 1,
        sku: ODMON_OVERNIGHT_MONITORING
      }
    case MonitoringPlan.odmon247:
      return {
        name: 'Pro Plus Monitoring',
        quantity: 1,
        sku: ODMON_247_MONITORING
      }
    default:
      return null
  }
}

function AddToCartButtonPackage({
  packageName,
  packageProducts,
  packageSku,
  setError,
  packageType,
  className,
  isOdmonVariant = false
}: Props) {
  const [loading, setLoading] = useState(false)
  const [items] = useAtom(itemQuantityAtom)
  const { locale } = useEnv()
  const trackAddToCart = useTrackATCPlanSelection()

  const [monitoring] = useAtom(monitoringAtom)

  const [proSetup] = useAtom(proSetupAtom)
  const { trackAddToCartEvent, trackAddToCartPackageWithExtrasEvent } =
    useTrackAddToCart()

  const microCopy = useMicroCopy()
  const dispatch = useDispatch()

  const { getProduct } = useProductContext()

  const isActivePromoLoading = useIsCurrentPromoLoading()
  const { isLoading: isPriceLoading } = usePriceContext()
  const isLoading = loading || isPriceLoading || isActivePromoLoading
  const hasMonitoring = hasMonitoringPlan(monitoring.type)

  const draftCartProducts = items.toArray().map(([sku, quantity]) => ({
    name: getProduct(sku)?.name || '',
    quantity,
    sku
  }))

  const monitoringAddToCartData = getMonitoringAddToCartData({ monitoring })
  const partnerData = getPartnerCookie()

  const proSetupData = pipe(
    proSetup,
    O.map(sku => ({
      name: 'Pro Setup',
      quantity: 1,
      sku
    })),
    O.toNullable
  )

  const products =
    pipe(
      draftCartProducts,
      O.fromNullable,
      O.map(draftCartProducts =>
        monitoringAddToCartData
          ? draftCartProducts.concat(monitoringAddToCartData)
          : draftCartProducts
      ),
      O.map(productsWithMonitoring =>
        proSetupData
          ? productsWithMonitoring.concat(proSetupData)
          : productsWithMonitoring
      ),
      O.toNullable
    ) || []

  /**
   * This chunk of code heavily depends on redux state and needs to be rewritten
   * to be agnostic of state management.
   */
  const { _package, packageProduct, additionalItems, selectState } =
    useReduxPackageSelectors(packageSku, proSetup)

  const { regularPrice, discountedPrice } = usePackagePrice(
    packageSku,
    packageType,
    []
  )

  const monitoringProduct = {
    brand: packageName,
    name: '',
    quantity: 1,
    masterSku: monitoring.plan,
    price: 0
  }

  const additionalItemsWithMonitoring = pipe(
    additionalItems,
    O.fromNullable,
    O.map(items =>
      hasMonitoring
        ? items.concat(convertOption(O.of(monitoringProduct)))
        : items
    ),
    O.toNullable
  )

  const addToCart = useCallback(() => {
    const isUS = locale === 'en-US'
    const refurbishedDiscount = pipe(
      match(packageType)
        .with('Refurbished', () => O.some('SIMPLIREFURB50'))
        .otherwise(() => O.none),
      O.toUndefined
    )
    const dynamicPackageSku = pipe(
      match(packageType)
        .with('Refurbished', () =>
          O.some(`simplisafe-custom-home-security-system-refurbished-${locale}`)
        )
        .otherwise(() =>
          O.some(`simplisafe-custom-home-security-system-${locale}`)
        ),
      O.getOrElse(() => '')
    )

    // US + interactive or ss2 = /cart
    // US + none = /choose-monitoring
    // UK + interactive = /choose-monitoring2
    // UK + none = /choose-monitoring2
    const url = isUS
      ? monitoring.type === MonitoringPlan.interactive ||
        monitoring.type === MonitoringPlan.ss2 ||
        monitoring.type === MonitoringPlan.odmonOvernight ||
        monitoring.type === MonitoringPlan.odmon247
        ? '/cart'
        : isOdmonVariant
          ? '/choose-monitoring-3'
          : '/choose-monitoring'
      : '/choose-monitoring2'
    setLoading(true)
    const onSuccess = () => {
      const packagePrice = O.toNullable(discountedPrice) || regularPrice
      trackAddToCart(monitoring.plan, 'pdp')
      trackAddToCartPackageWithExtrasEvent(
        _package,
        packageProduct,
        additionalItemsWithMonitoring || [],
        false,
        selectState,
        hasMonitoring,
        null,
        packagePrice
      )

      setLoading(false)
      handleAddToCartRedirection(url, packageSku)
    }

    const onFailure = () => {
      setLoading(false)
      setError(true)
    }

    const convertToProducts = (products: readonly PackageProduct[]) => {
      const excludedSkus = [
        'SSBS3W',
        'SSWD1',
        'SSCATM1-SS3-VZ',
        'SSCATM1-SS2-ATT',
        'SIM340-VF',
        'MI023'
      ]
      return products
        .filter(product => !excludedSkus.includes(product.sku))
        .map(product => {
          return {
            sku: product.sku,
            quantity: product.quantity
          }
        })
    }

    match(packageType)
      .with('Dynamic', () => {
        dispatch(
          IOAddBmsToCart(
            {
              package: {
                quantity: 1,
                sku: packageSku
              },
              products: products.map(({ quantity, sku }) => ({
                quantity,
                sku
              }))
            },
            onFailure,
            onSuccess
          )
        )
      })
      .otherwise(() => {
        dispatch(
          IOAddDynamicPrebuiltToCart(
            {
              package: {
                quantity: 1,
                sku: dynamicPackageSku,
                custom: {
                  lineItemDisplayName: packageName,
                  lineItemSku: packageSku
                }
              },
              products: [...convertToProducts(packageProducts)],
              discountCode: refurbishedDiscount
            },
            {
              products: products.map(({ quantity, sku }) => ({
                quantity,
                sku
              }))
            },
            onFailure,
            onSuccess
          )
        )
      })

    // Adding partner association to cart here in case the cart is not in
    // the correct state when the user lands on the partner landing page
    const cartId = getCartId() || ''
    partnerData?.partnerName &&
      dispatch(
        IOCreateOrSetPartnerAssociationCart(
          partnerData.partnerGroup,
          partnerData.partnerName,
          cartId,
          onFailure
        )
      )
  }, [
    dispatch,
    locale,
    monitoring,
    setError,
    products,
    trackAddToCartEvent,
    trackAddToCartPackageWithExtrasEvent,
    packageSku,
    _package,
    packageProduct,
    additionalItems,
    selectState,
    hasMonitoringPlan,
    packageType
  ])

  return (
    <SimpleButton
      aria-label="Add to cart"
      className={classNames(
        'w-52 shrink-0 self-center text-base md:w-64 md:text-lg lg:self-auto',
        className
      )}
      disabled={isLoading}
      onClick={addToCart}
      variant="solid"
    >
      {isLoading ? (
        <LoadingSpinner fillColor="var(--complementary-blue-100)" size={24} />
      ) : (
        <>
          {microCopy['add-to-cart']}
          <span className="sr-only">(opens in new tab)</span>
        </>
      )}
    </SimpleButton>
  )
}

export default AddToCartButtonPackage
