import {
  contentfulRichTextSchema,
  gatsbyImageSchema
} from '@ecomm/contentful-schemas'
import { experimentTemplateSchema } from '@ecomm/shared-ninetailed'
import { type TypeOf, z } from '@simplisafe/ewok'

import { accessoriesModalSchema } from '../AccessoriesModal/schema'
import { colorVariantSchema } from '../ColorSelector/schema'
import { carouselItem } from '../FloorPlan/schema'
import { outOfStockButtonSchema } from '../OutOfStockButton/schema'
import { productModalSchema } from '../ProductModal/schema'

export const productCardInternalSchema = z.object({
  id: z.string(),
  description: contentfulRichTextSchema,
  disclaimer: contentfulRichTextSchema.nullable().optional(),
  floorplanCarouselItem: carouselItem.nullable().optional(),
  image: gatsbyImageSchema.optional().nullable(),
  imageWithBg: gatsbyImageSchema.nullish().optional(),
  isNew: z.boolean(false),
  maxQuantity: z.number(),
  name: z.string(),
  namePlural: z.string(),
  outOfStock: outOfStockButtonSchema.nullable().optional(),
  priceText: z.string().nullable().optional(),
  productModal: productModalSchema.nullable().optional(),
  proTip: contentfulRichTextSchema.nullable().optional(),
  shortDescription: contentfulRichTextSchema.nullable().optional(),
  sku: z.string(),
  addToCartButton: z.boolean().optional(),
  slug: z.string().optional(),
  productLink: z.string().optional().nullable(),
  className: z.string().optional().nullable(),
  variant: z.nullable(z.array(colorVariantSchema)),
  badge: z.string().optional().nullable()
})

export const productCardSchema = productCardInternalSchema.extend({
  accessoriesModal: accessoriesModalSchema.nullable().optional(),
  disableMonthlyPricing: z.boolean(false).optional(),
  disablePromo: z.boolean(false).optional(),
  isAffirmExperience: z.boolean().optional(),
  isOdmonVariant: z.boolean().default(false).optional()
})

const experimentSchema = experimentTemplateSchema.extend({
  variants: z
    .array(
      productCardInternalSchema.extend({
        description: contentfulRichTextSchema.nullable().optional()
      })
    )
    .default([])
})

export const productCardExperienceSchema = productCardSchema.extend({
  nt_experiences: z.array(experimentSchema).optional().default([])
})

export type ProductCardFragment = TypeOf<typeof productCardSchema>
export type ProductCardExperimentFragment = TypeOf<
  typeof productCardExperienceSchema
>
