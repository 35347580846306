import { ContentfulRichText } from '@ecomm/shared-components'
import classNames from 'classnames'

import type { SmallTextSchema } from './schema'

export function SmallText({ text, textAlignment }: SmallTextSchema) {
  return (
    <div
      className={classNames(
        'w-full',
        {
          'text-left': textAlignment === 'Left',
          'text-center': textAlignment === 'Center',
          'text-right': textAlignment === 'Right'
        },
        'prose-h1:mb-4'
      )}
      data-testid="SmallText"
    >
      <ContentfulRichText {...text} />
    </div>
  )
}
