import type { Options } from '@contentful/rich-text-react-renderer'
import { BLOCKS } from '@contentful/rich-text-types'
import { trackSensorAdd, trackSensorRemove } from '@ecomm/cdp-tracking'
import { useTrackAddProductToCart } from '@ecomm/cdp-tracking-utils'
import {
  AFFIRM_TERM_MONTHS_SYSTEM,
  AFFIRM_TERM_MONTHS_SYSTEM_EXPERMIENT
} from '@ecomm/data-constants'
import { useLocale } from '@ecomm/data-hooks'
import { usePriceContext } from '@ecomm/data-price'
import { useProductDeprecated } from '@ecomm/data-products'
import { usePopupWizardResponses } from '@ecomm/data-storage'
import { logError } from '@ecomm/error-handling'
import { useMicroCopy } from '@ecomm/micro-copy'
import {
  ContentfulRichText,
  GatsbyImage,
  NewFlag,
  QuantitySelector
} from '@ecomm/shared-components'
import { SSButton } from '@ecomm/ss-react-components'
import {
  useTrackingBmsShieldCameraAccesoriesExpand,
  useTrackingBmsToolTip,
  useTrackingProductAddToCart,
  useTrackingProductDecrease,
  useTrackingProductIncrease
} from '@ecomm/tracking'
import { voidFn } from '@simplisafe/ewok'
import { path } from '@simplisafe/ewok'
import { Maybe } from '@simplisafe/ewok'
import { IOAddToCart } from '@simplisafe/ss-ecomm-data/cart'
import type { Product } from '@simplisafe/ss-ecomm-data/commercetools/products'
import classNames from 'classnames'
import { pipe } from 'fp-ts/lib/function'
import * as O from 'fp-ts/lib/Option'
import { isString } from 'fp-ts/lib/string'
import { Link } from 'gatsby'
import { useAtom, useSetAtom } from 'jotai'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'

import {
  changeColorVariantAtom,
  decrementItemQtyAtom,
  incrementItemQtyAtom
} from '../../atoms/draftCart/itemQuantityAtom'
import { monitoringAtom } from '../../atoms/draftCart/monitoringPlanAtom'
import { useItemQuantity } from '../../hooks/draftCart/useItemQuantity'
import AccessoriesModal from '../AccessoriesModal'
import BackorderMessage from '../BackorderMessage'
import ColorSelector from '../ColorSelector'
import { MonitoringPlan } from '../DraftCart/types'
import OutOfStockButton from '../OutOfStockButton'
import ProductCartModal from '../ProductCartModal'
import ProductModal from '../ProductModal'
import { useGiftItemsProductCard } from './hooks'
import type { ProductCardFragment } from './schema'

const getDefaultOptions = (): Options => {
  return {
    renderNode: {
      [BLOCKS.HEADING_6]: (node, children) => {
        return <p className=" !text-sm ">{children}</p>
      }
    }
  }
}

/**
 *
 * @returns A ProductCard that can be used across separate UI experiences. As a result,
 * this component has dual interfaces for state management over quantity and sku selection
 * in order to keep DraftCart in sync (build-my-system experience) vs single product purchase
 * experiences (alarm-sensors).
 */
function ProductCard({
  disclaimer,
  priceText,
  productModal,
  accessoriesModal,
  name,
  description,
  disableMonthlyPricing = false,
  disablePromo = false,
  outOfStock,
  className,
  shortDescription,
  proTip,
  image,
  maxQuantity,
  sku: defaultSku,
  addToCartButton,
  isNew,
  productLink,
  variant,
  badge,
  isAffirmExperience
}: ProductCardFragment) {
  const isUs = useLocale() === 'en-US'
  const [sku, setSku] = useState(defaultSku)
  const { getFormattedPrice } = usePriceContext()
  const [monitoring] = useAtom(monitoringAtom)
  const itemQuantityAtom = useItemQuantity(sku)
  const increase = useSetAtom(incrementItemQtyAtom)
  const decrease = useSetAtom(decrementItemQtyAtom)
  const changeColor = useSetAtom(changeColorVariantAtom)

  const [popupWizardResponses] = usePopupWizardResponses()
  const dispatch = useDispatch()

  const freeGiftItems = useGiftItemsProductCard(sku)
  const product = useProductDeprecated(sku)
  const microCopy = useMicroCopy()
  const [isProductModalOpen, setIsProductModalOpen] = useState(false)
  const [isAccessoriesModalOpen, setIsAccessoriesModalOpen] = useState(false)
  const [isProductCartModalOpen, setIsProductCartModalOpen] = useState(false)

  const [isSellable, setIsSellable] = useState(true)
  const [isOnStock, setIsOnStock] = useState(true)
  const [restockDate, setRestockDate] = useState('')

  const [showCartUpdated, setShowCartUpdated] = useState(false)

  const getDefaultQuantity = useCallback(
    () => (addToCartButton ? itemQuantityAtom || 1 : itemQuantityAtom),
    [addToCartButton, itemQuantityAtom]
  )

  const [quantity, setQuantity] = useState(getDefaultQuantity())

  const price = pipe(
    getFormattedPrice(sku),
    O.getOrElse(() => '')
  )

  const affirmMonths = isAffirmExperience
    ? AFFIRM_TERM_MONTHS_SYSTEM_EXPERMIENT
    : AFFIRM_TERM_MONTHS_SYSTEM
  const monthlyPrice = pipe(
    O.Do,
    O.filter(() => !disableMonthlyPricing),
    O.chain(() => getFormattedPrice(sku, affirmMonths)),
    O.getOrElse(() => '')
  )

  //useEffect to precheck checkbox if user selected for proinstall in popup wizard
  useEffect(() => {
    pipe(
      popupWizardResponses,
      O.chain(responses => (sku === 'SSPSH-ON' ? O.of(responses) : O.none)),
      O.chain(responses => O.fromNullable(Number(responses[8]))),
      O.chain(response8 => (response8 === 22 ? O.of(response8) : O.none)),
      O.fold(voidFn, () => onIncrease())
    )
  }, [])

  useEffect(() => {
    pipe(
      product,
      O.fold(
        () => {
          setIsSellable(true)
          setIsOnStock(true)
          setRestockDate('')
        },
        p => {
          p.isSellable ? setIsSellable(true) : setIsSellable(false)
          p.isOnStock ? setIsOnStock(true) : setIsOnStock(false)
          setRestockDate(p.restockDate || '')
        }
      )
    )
  }, [product])

  useEffect(() => {
    setQuantity(getDefaultQuantity())
  }, [itemQuantityAtom, getDefaultQuantity])

  const monitoringType = useMemo(() => {
    return monitoring.type === MonitoringPlan.interactive ||
      monitoring.type === MonitoringPlan.odmonOvernight ||
      monitoring.type === MonitoringPlan.odmon247
      ? 'withMonitoring'
      : 'withoutMonitoring'
  }, [monitoring])

  const showBackorderMessage =
    isSellable &&
    !isOnStock &&
    isString(restockDate) &&
    restockDate !== '' &&
    defaultSku === sku && // temporary fix to show OOS messaging for White Smart Lock and not Black Smart Lock, can be removed after 01/02/2024
    quantity > 0

  const getProductValue = () => {
    //@ts-expect-error
    const productValue: Product = path(['value'], product)
    //@ts-expect-error
    const discountedPrice = path(['discountedPrice', 'value'], productValue)

    return (
      productValue && {
        ...productValue,
        discountedPrice: Maybe.of(
          discountedPrice ? discountedPrice : productValue.price
        )
      }
    )
  }

  const trackDecrease = useTrackingProductDecrease(name)
  const trackIncrease = useTrackingProductIncrease(name)
  const trackAccessoriesModalOpen =
    useTrackingBmsShieldCameraAccesoriesExpand(name)
  const trackProductModalOpen = useTrackingBmsToolTip(name)
  const trackAddToCart = useTrackingProductAddToCart(getProductValue(), name)
  const { trackProductCardAddToCartEvent } = useTrackAddProductToCart()

  const onChangeColor = useCallback(
    (newSku: string) => {
      quantity !== 0 && changeColor({ oldSku: sku, newSku })
      setSku(newSku)
      quantity !== 0 && setShowCartUpdated(false)
    },
    [changeColor, sku, quantity]
  )

  const onDecrease = useCallback(() => {
    if (addToCartButton) {
      return setQuantity(quantity - 1)
    } else {
      decrease(sku)
      setShowCartUpdated(true)
      trackSensorRemove(name)
      return trackDecrease()
    }
  }, [
    sku,
    decrease,
    trackDecrease,
    setQuantity,
    quantity,
    addToCartButton,
    name
  ])

  const onIncrease = useCallback(() => {
    if (addToCartButton) {
      return setQuantity(quantity + 1)
    } else {
      increase(sku, maxQuantity)
      setShowCartUpdated(true)
      trackSensorAdd(name)
      return trackIncrease()
    }
  }, [
    sku,
    increase,
    trackIncrease,
    setQuantity,
    quantity,
    maxQuantity,
    addToCartButton,
    name
  ])

  // TODO: why is this not using an existing AddToCartButton component? this is duplicate logic
  const addToCart = useCallback(() => {
    const handleSuccess = () => {
      trackProductCardAddToCartEvent(getProductValue(), quantity)
      trackAddToCart(quantity)
      setIsProductCartModalOpen(true)
    }

    const handleFailure = () => {
      logError(Error('Cannot add to cart: unexpected error'))
    }

    dispatch(
      IOAddToCart(
        {
          products: [
            {
              quantity,
              sku
            }
          ]
        },
        handleFailure,
        handleSuccess
      )
    )
  }, [sku, quantity, trackAddToCart, trackProductCardAddToCartEvent, dispatch])

  const onAccessoriesModalOpenClose = useCallback(
    (open: boolean) => {
      setIsAccessoriesModalOpen(open)
      open && trackAccessoriesModalOpen()
    },
    [trackAccessoriesModalOpen]
  )

  const onProductModalOpenClose = useCallback(
    (open: boolean) => {
      setIsProductModalOpen(open)
      open && trackProductModalOpen()
    },
    [trackProductModalOpen]
  )

  const onProductCartModalOpenClose = useCallback(
    (open: boolean) => {
      setIsProductCartModalOpen(open)
    },
    [setIsProductCartModalOpen]
  )

  const renderProductName = () => (
    <h3
      className={classNames('mb-0 text-base font-bold md:mt-4 md:text-2xl', {
        'hover:text-btn-primary': productLink
      })}
    >
      {name}
    </h3>
  )

  // We cannot sell Outdoor Camera Battery in the UK because we can't ship it to Northern Ireland.
  // This is a very specific condition that only affects UK and only one product.
  // So we are hardcoding this condition here.
  const isSellableInTheUK = useMemo(() => {
    return isUs ? true : sku !== 'SSCAM-BAT1'
  }, [isUs, sku])

  /**
   * shortDescription fallback logic:
   * - undefined: use description for both mobile and desktop
   * - defined: use description for desktop, but use shortDescription for mobile
   * mobile and desktop displays are controlled using css queries
   */
  return (
    <div
      className={`prose relative flex flex-1 flex-col items-center rounded-3xl bg-white px-3 py-8 ${
        className ? className : ''
      }`}
      data-testid="product-card"
    >
      <div className="flex items-center gap-4 md:flex-col md:gap-0">
        <div className="relative basis-1/3 md:w-40">
          {image ? (
            <GatsbyImage
              image={image}
              imgStyle={{ objectFit: 'contain' }}
              key={image.title}
            />
          ) : null}
          {isNew ? (
            <div
              className={classNames(
                'absolute left-4 top-[-20px] md:left-2 md:top-10'
              )}
            >
              <NewFlag hasBackground />
            </div>
          ) : null}
        </div>
        <div className="basis-2/3 text-left md:text-center">
          {productLink ? (
            <Link
              className="hover:bg-btn-primary no-underline hover:text-white"
              to={productLink}
            >
              {renderProductName()}
            </Link>
          ) : (
            renderProductName()
          )}
          {badge ? (
            <div className="flex w-full md:justify-center">
              <div className="text-primary mt-2 w-fit rounded-[4px] bg-[#4FCBF7] p-1 text-center text-[9px] font-bold leading-none md:text-xs md:leading-4">
                {badge}
              </div>
            </div>
          ) : null}
          {!shortDescription && (
            <div className="prose-p:my-2 prose-p:lg:text-lg">
              <ContentfulRichText
                optionsCustom={getDefaultOptions()}
                raw={description.raw}
              />
            </div>
          )}
          {shortDescription ? (
            <div className="prose-p:my-2 prose-p:lg:text-lg hidden md:block">
              <ContentfulRichText
                optionsCustom={getDefaultOptions()}
                raw={description.raw}
              />
            </div>
          ) : null}
          {shortDescription ? (
            <div className="prose-p:my-2 prose-p:lg:text-lg md:hidden">
              <ContentfulRichText
                optionsCustom={getDefaultOptions()}
                raw={shortDescription.raw}
              />
            </div>
          ) : null}
          {proTip ? (
            <div className="prose-p:my-2 prose-p:lg:text-lg mb-2 hidden md:inline">
              <ContentfulRichText raw={proTip.raw} />
            </div>
          ) : null}
          {accessoriesModal ? (
            <AccessoriesModal
              modal={accessoriesModal}
              open={isAccessoriesModalOpen}
              setOpen={onAccessoriesModalOpenClose}
            />
          ) : null}
        </div>
      </div>
      <p
        className={classNames('text-complementary-blue-100 mt-auto', {
          'animate-fadeInOut': showCartUpdated,
          invisible: !showCartUpdated
        })}
        onAnimationEnd={() => setShowCartUpdated(false)}
      >
        {microCopy['system-updated']}
      </p>
      <h4 className="font-medium empty:hidden">{priceText}</h4>
      {freeGiftItems[monitoringType] && !disablePromo ? (
        <p className="text-md mb-6 text-center text-red-500">
          One free {`${freeGiftItems[monitoringType]?.title}`} will be
          automatically added to your new system
        </p>
      ) : null}
      {(!isSellable || !isSellableInTheUK) && outOfStock ? (
        <OutOfStockButton {...outOfStock} />
      ) : (
        <div
          className={classNames(
            'rounded-base flex w-full flex-wrap justify-around',
            {
              'border-neutral-light-100 border-2 border-solid p-4':
                maxQuantity && maxQuantity > 1,
              'flex-col items-center p-0': maxQuantity === 1
            }
          )}
        >
          {variant ? (
            <ColorSelector
              data={variant}
              defaultSku={defaultSku}
              onChangeColor={onChangeColor}
              placement="ProductCard"
            />
          ) : null}
          {isUs && monthlyPrice ? (
            <div className="text-lg font-semibold">
              {`${monthlyPrice}/mo`}
              <div className="text-sm font-normal">{`or ${price}`}</div>
            </div>
          ) : (
            <h4 className="my-2 font-bold empty:hidden">{price}</h4>
          )}

          <QuantitySelector
            maxQuantity={maxQuantity}
            name={name}
            onDecrease={onDecrease}
            onIncrease={onIncrease}
            quantity={quantity}
          />
        </div>
      )}
      {disclaimer ? (
        <div className="mt-2 text-center text-base">
          <ContentfulRichText raw={disclaimer.raw} />
        </div>
      ) : null}
      {showBackorderMessage ? (
        <div className="mt-3 text-center">
          <BackorderMessage
            includedInPackage={true}
            restockDate={restockDate}
          />
        </div>
      ) : null}
      <div className="mt-4 flex">
        <span
          className={classNames(
            'm-0 cursor-pointer text-center text-lg underline',
            {
              invisible: !productModal
            }
          )}
          onClick={e => {
            e.preventDefault()
            !isProductModalOpen && onProductModalOpenClose(true)
          }}
        >
          See details
        </span>
        {productModal ? (
          <ProductModal
            isNew={isNew}
            modal={productModal}
            open={isProductModalOpen}
            setOpen={onProductModalOpenClose}
          />
        ) : null}
      </div>
      {addToCartButton && isSellable ? (
        <div className="flex justify-center pt-8">
          <SSButton color="primaryOutline" onClick={addToCart}>
            {microCopy['add-to-cart']}
          </SSButton>
        </div>
      ) : null}
      {isProductCartModalOpen ? (
        <ProductCartModal
          image={image}
          open={isProductCartModalOpen}
          price={price}
          quantity={quantity}
          setOpen={onProductCartModalOpenClose}
          title={name}
        />
      ) : null}
    </div>
  )
}

export default ProductCard
