import { useLocale } from '@ecomm/data-hooks'
import {
  monthlyToLocalizedDailyPrice,
  usePriceContext
} from '@ecomm/data-price'
import { useMicroCopy } from '@ecomm/micro-copy'
import {
  type IconAndTextSchema,
  ContentfulRichText
} from '@ecomm/shared-components'
import { CheckSolid, XSolidIcon } from '@ecomm/shared-icons'
import { isNotNil } from '@simplisafe/ewok'
import classNames from 'classnames'
import { match } from 'fp-ts/lib/Option'
import React, { useState } from 'react'

import { AddPlanToCartButtonProduct } from '../AddPlanToCartButton'
import FeatureModal from '../FeatureModal'
import IconTextGrid from '../IconTextGrid'
import PlanConfirmationModal from '../PlanConfirmationModal'
import type { MonitoringPlanCardSchema } from './schema'

type MonitoringPlanCardProps = MonitoringPlanCardSchema & {
  // packageSku is coming from reach/router's location state from parent component
  // as we need to show a discount offer text if it exists based on that in
  // the MonitoringPlan card.
  readonly packageSku?: string
}

export default function MonitoringPlanCard(data: MonitoringPlanCardProps) {
  const {
    recommended,
    name,
    sku,
    features,
    cta,
    additionalFeatures,
    disclaimer,
    description,
    planConfirmationModal,
    packageSku
  } = data

  const locale = useLocale()
  const microcopy = useMicroCopy()
  const [isOpenModal, setIsOpenModal] = useState(false)
  const { getPrice, getDiscountText, getDiscountTextWithServicePlan } =
    usePriceContext()
  const priceText = match(
    () => null,
    (price: number) => monthlyToLocalizedDailyPrice(locale, price)
  )(getPrice(sku))

  const additionalFeaturesWithIcons: readonly IconAndTextSchema[] =
    additionalFeatures
      .map(feature =>
        feature.__typename === 'ContentfulIconAndText' ? feature : null
      )
      .filter(isNotNil)

  const completeOfferText = (discountText: string) => (
    <span className="darkOrangeTextColor ml-4 whitespace-pre-line text-left">
      {`+${discountText} off your system today`}
    </span>
  )

  const offerTextDiscount = match(
    () => null,
    (discountText: string) => completeOfferText(discountText)
  )(
    getDiscountTextWithServicePlan(packageSku || '') ||
      getDiscountText(packageSku || '')
  )

  return (
    <div className="flex w-full flex-col" data-testid="MonitoringPlanCard">
      <div className="flex flex-col md:flex-row">
        <div className="border-neutral-medium-100 flex flex-grow basis-[55%] flex-col gap-3 rounded-t-2xl border border-solid px-4 py-5 md:rounded-l-2xl md:rounded-tr-none md:pb-6 md:pt-8 lg:pl-16 lg:pr-14">
          <span
            className={classNames(
              'bg-primary-100 mr-auto px-[10px] py-[7px] text-xs',
              { invisible: !recommended }
            )}
          >
            {microcopy['recommended']}
          </span>
          <h3 className="my-0 text-xl font-bold leading-normal">{name}</h3>
          <ul className="my-0 mb-2 flex flex-col gap-1 pl-2">
            {features?.map((feature, idx) => (
              <li
                className="prose-p:text-base prose-p:mb-0 flex list-none items-center gap-1"
                key={idx}
              >
                {feature.icon === 'CheckMark' ? (
                  <CheckSolid className="text-complementary-green-100 h-6 w-6 shrink-0" />
                ) : (
                  <XSolidIcon className="text-complementary-red-100 h-6 w-6 shrink-0" />
                )}
                <ContentfulRichText {...feature.helpText} />
                {feature.modal ? <FeatureModal modal={feature.modal} /> : null}
              </li>
            ))}
          </ul>
          <div className="mb-6 flex flex-grow flex-col">
            <div className="prose-p:text-base">
              <ContentfulRichText {...description} />
            </div>
            <div className="mb-1 flex flex-col items-center gap-2 md:flex-row md:items-baseline">
              <div className="flex w-full flex-col-reverse items-center gap-2 md:w-auto md:flex-row">
                {cta ? (
                  recommended ? (
                    <AddPlanToCartButtonProduct
                      buttonText={cta}
                      className="w-full md:w-[12rem]"
                      sku={sku}
                    />
                  ) : (
                    <button
                      className="btn btn-solid-primary w-full md:w-[12rem]"
                      onClick={() => setIsOpenModal(true)}
                    >
                      {cta}
                    </button>
                  )
                ) : null}
                <div className="flex items-center gap-2">
                  <span
                    className={classNames('h3', {
                      'text-complementary-red-100': recommended
                    })}
                  >
                    {priceText}
                  </span>
                  <span className="text-xs">per day</span>
                </div>
              </div>
              {recommended && offerTextDiscount ? (
                <p>{offerTextDiscount}</p>
              ) : null}
            </div>
            <p className="text-base">{microcopy['no-contract']}</p>
          </div>
        </div>
        <IconTextGrid items={additionalFeaturesWithIcons} />
      </div>
      {disclaimer ? (
        <span className="pt-5 text-center text-xs">{disclaimer}</span>
      ) : null}
      {planConfirmationModal ? (
        <PlanConfirmationModal
          isOpenModal={isOpenModal}
          locale={locale}
          name={name}
          planConfirmationModal={planConfirmationModal}
          setIsOpenModal={setIsOpenModal}
          sku={sku}
        />
      ) : null}
    </div>
  )
}
