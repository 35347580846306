/* eslint-disable @typescript-eslint/consistent-type-assertions */
import { trackCartCouponApplied } from '@ecomm/cdp-tracking'
import {
  getCartDiscountCode,
  getCartDiscountValue,
  getCartSubTotal
} from '@ecomm/data-cart'
import { applyDiscount } from '@ecomm/data-cart'
import {
  OLYMPUS_SKU,
  SCOUT_SKU,
  SHIELD_SKU,
  SIMPLICAM_SKU
} from '@ecomm/data-constants'
import { useLocale } from '@ecomm/data-hooks'
import { useUtmCode } from '@ecomm/promotions-hooks'
import {
  type AffirmClient,
  AffirmMonthlyOption,
  AffirmPromoMessage
} from '@ecomm/shared-components'
import { setCookie } from '@ecomm/shared-cookies'
import { BannerError, LoadingSpinner } from '@ecomm/ss-react-components'
import { useMediaQuery } from '@ecomm/ss-react-components'
import {
  brazeTrackCartDetails,
  trackRemoveCartEvent,
  trackSystemClick,
  useOptimizelyAffirm,
  useOptimizelyTrackSiteEvents
} from '@ecomm/tracking'
import { useTrackMetricEvent } from '@ecomm/tracking'
import { getPackageIndexInArray } from '@ecomm/tracking/src/utils'
import { prop } from '@simplisafe/ewok'
import { localStorage } from '@simplisafe/ewok'
import { safeFind, safePath, safeProp } from '@simplisafe/monda'
import {
  clearCartError,
  IOAddToCart,
  IORemoveFromCart,
  IOUpdateQuantity
} from '@simplisafe/ss-ecomm-data/cart'
import {
  getShippingInfoKey,
  LOCAL_STORAGE_CARTID
} from '@simplisafe/ss-ecomm-data/cart/actions'
import { selectCartLoading } from '@simplisafe/ss-ecomm-data/cart/select'
import type {
  ImmutableCart,
  LineItem
} from '@simplisafe/ss-ecomm-data/commercetools/cart'
import {
  selectCart,
  selectHiddenProductSkus,
  selectProduct,
  selectProducts
} from '@simplisafe/ss-ecomm-data/redux/select'
import type { ImmutableState } from '@simplisafe/ss-ecomm-data/redux/state'
import { window } from 'browser-monads-ts'
import { pipe } from 'fp-ts/lib/function'
import * as O from 'fp-ts/lib/Option'
import { Maybe, None } from 'monet'
import always from 'ramda/src/always'
import defaultTo from 'ramda/src/defaultTo'
import equals from 'ramda/src/equals'
import F from 'ramda/src/F'
import ifElse from 'ramda/src/ifElse'
import isEmpty from 'ramda/src/isEmpty'
import length from 'ramda/src/length'
import T from 'ramda/src/T'
import uniq from 'ramda/src/uniq'
import {
  type ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTracking } from 'react-tracking'
import { debounce, throttle } from 'throttle-debounce'

import type {
  ContentfulCartDetails,
  ContentfulProductInformation
} from '../../../graphql'
import {
  componentsNotInStock,
  renderCartLineOutOfStockMessage,
  systemCoreComponents
} from '../../commercetools/outOfStock'
import { formatDisplayPrice } from '../../commercetools/price'
import type { CartPageContext } from '../../templates/CartPage'
import { trackAddToCartEvent } from '../../util/analytics/addToCart'
import { trackLoadCartEvent } from '../../util/analytics/loadCart'
import { getLastCheckoutComponents } from '../CheckoutForm/utils'
import { onApplyCouponTrack } from './cart-sections/CartApplyCoupon/utils'
import {
  AffirmCard,
  CustomerServiceCard,
  MonitoringPlanCard,
  TryItTestItCard
} from './cart-sections/CartBanners'
import CartLineItemDescription from './cart-sections/CartLineItemDescription'
import type { CartDetailsProps } from './CartContent'
import { getCartDetails } from './getCartDetails'
// TODO refactor line item logic in cart details to use toItemList from @ecomm/checkout/lineItems
import { toItemList } from './transformLineItem'
import type { CartLineItemProps, DiscountProps, ServerError } from './types'
import {
  cameraSensorQuantityInCart,
  getFreeShippingItem,
  getOlympusMaxQuantity,
  getQuantityInCart,
  getScoutMaxQuantity,
  getShieldMaxQuantity,
  getSimplicamMaxQuantity,
  navigateLink,
  productIsInCart,
  renderCart,
  renderScoutWarningMessage,
  renderSensorWarningMessage,
  shouldShowMonitoringPlanWidget,
  toContentfulCartDetails,
  toCoreComponentsOosProps
} from './utils'

const { get, set, remove } = localStorage
const CARTID = 'cartId'

type CartDetailsComponentProps = {
  readonly affirmClient?: AffirmClient
  readonly data: Partial<ContentfulCartDetails>
  readonly pageContext: CartPageContext
  readonly isAffirmExperiment: boolean
}

export default function CartDetailsComponent({
  affirmClient = window.affirm,
  data,
  pageContext,
  isAffirmExperiment
}: CartDetailsComponentProps) {
  const trackMetricEvent = useTrackMetricEvent()
  const siteLocale = useLocale()
  const isMobile = !useMediaQuery('TabletAndUp')
  // TODO this can just be an array instead of a Maybe
  const [lineItem, setLineItems] = useState(
    None<readonly CartLineItemProps[]>()
  )

  // the discounted total price
  const [totalPrice, setTotalPrice] = useState('')
  //the sub total, not including discount
  const [subTotalPrice, setSubTotalPrice] = useState(0)
  const [shieldQuantity, setShieldQuantity] = useState(0)
  const [scoutQuantity, setScoutQuantity] = useState(0)
  const [simplicamQuantity, setSimplicamQuantity] = useState(0)
  const [olympusQuantity, setOlympusQuanity] = useState(0)
  const [cameraSensorQuantity, setCameraSensorQuantity] = useState(0)
  const dispatch = useDispatch()
  const cart = useSelector(selectCart)
  const hiddenProductSkus = useSelector(selectHiddenProductSkus)
  const [isMounted, setMounted] = useState(false)
  const productMaxQuantity = useMemo(
    () => prop('productMaxQuantity', data) || [],
    [data]
  )
  const shieldMaxQuantity = getShieldMaxQuantity(productMaxQuantity)
  const scoutMaxQuantity = getScoutMaxQuantity(productMaxQuantity)
  const simplicamMaxQuantity = getSimplicamMaxQuantity(productMaxQuantity)
  const olympusMaxQuantity = getOlympusMaxQuantity(productMaxQuantity)

  const subItemsSkusRepeated = lineItem
    .orJust([])
    .reduce(
      (acc: readonly string[], item) =>
        acc.concat(
          (item?.subItems || []).map(subItem => subItem?.subItemSku || '')
        ),
      []
    )
  const subItemsSkus = uniq<string>(subItemsSkusRepeated)
  const itemsSkusRepeated = lineItem.orJust([]).map(item => item.sku || '')
  const itemsSkus = uniq<string>(itemsSkusRepeated)
  const subItemsProducts = useSelector(selectProducts(subItemsSkus))
  const itemsProducts = useSelector(selectProducts(itemsSkus))
  const cartIsLoading = useSelector(selectCartLoading)

  const freeMonitoringSku = safePath(['freeMonitoring', 'productId'], data)

  const { Track, trackEvent } = useTracking()
  const shippingText = defaultTo('')(prop('shippingText', data))
  const productReference = data?.productReference || []

  const freeMonitoringDetails = useSelector((state: ImmutableState) =>
    freeMonitoringSku.chain(sku => selectProduct(sku)(state).toMaybe())
  ).toEither(Error('Unable to obtain free monitoring product details'))

  const coreComponentsProducts = useSelector(
    selectProducts(systemCoreComponents)
  )

  const coreComponentsNotInStockList = useMemo(
    () => componentsNotInStock(coreComponentsProducts),
    [coreComponentsProducts]
  )

  const liftFreeMonitoringSku = useMemo(
    () => freeMonitoringSku.map(shouldShowMonitoringPlanWidget),
    [freeMonitoringSku]
  )

  const productMessageWithoutSystemData = safeProp(
    'productMessageWithoutSystem',
    data
  ) as Maybe<readonly ContentfulProductInformation[]>

  const [productWarningMessage, setProductWarningMessage] = useState(
    None<ContentfulProductInformation>()
  )
  const { optimizelyAffirmLearnMore } = useOptimizelyAffirm()
  const optimizelyTrackSiteEvents = useOptimizelyTrackSiteEvents()

  const [promoCode, setPromoCode] = useState('')
  const [isCouponError, setIsCouponError] = useState(false)
  const [isCouponSuccess, setIsCouponSuccess] = useState(false)

  // Indicates whether the coupon field is in an 'open' or 'closed' state
  const [isClose, setIsClose] = useState(true)

  // Set the intiial value of the promoCode on render with cart as a dependency
  useEffect(() => {
    const cartDiscountValue = cart.fold('')(_cart => getCartDiscountCode(_cart))

    // If the coupon entry window is closed, set promo code to cart value for decision logic. Otherwise,
    // set it to empty string to avoid successes on empty submissions.
    isClose && promoCode === '' && setPromoCode(cartDiscountValue)
  }, [cart, setPromoCode, promoCode, isClose])

  /** handle add to cart in cart monitaoring right pane section */
  const onAddToCart = useCallback(() => {
    const handleSuccess = () => {
      optimizelyTrackSiteEvents({ eventType: 'add_to_cart_clicked' })
      trackAddToCartEvent(freeMonitoringDetails, trackEvent, 1)
    }

    freeMonitoringSku.forEach(sku =>
      dispatch(
        IOAddToCart(
          {
            products: [
              {
                quantity: 1,
                sku
              }
            ]
          },
          undefined,
          handleSuccess
        )
      )
    )
  }, [
    dispatch,
    freeMonitoringDetails,
    freeMonitoringSku,
    trackEvent,
    optimizelyTrackSiteEvents
  ])

  /**
   * If there is an error, or the monitoring plan should not render, this is false.
   *
   * If the `.apTo` is new to you, you can read more about applicative functors here: https://mostly-adequate.gitbook.io/mostly-adequate-guide/ch10
   **/
  const [showCartMonitoringPlan, setShowCartMonitoringPlan] = useState(
    liftFreeMonitoringSku
      .apTo(cart.toMaybe())
      .cata(F, shouldShowMonitoringPlanWidget => shouldShowMonitoringPlanWidget)
  )

  const affirmCardContent = AffirmCard(siteLocale)
  const customerServiceCardContent = CustomerServiceCard(siteLocale)
  const tryItTestItCardContent = TryItTestItCard()
  const cartMonitoringPlanContent = MonitoringPlanCard(
    siteLocale,
    showCartMonitoringPlan,
    onAddToCart
  )

  const onUpdateQuantity = debounce(
    500,
    (lineItemId: string, quantity: number) => {
      dispatch(IOUpdateQuantity(lineItemId, quantity))
    }
  )

  const onRemoveProduct = (lineItems: readonly LineItem[]) =>
    throttle(500, (lineItem: LineItem) => {
      const lineItemIndex = getPackageIndexInArray(lineItem, lineItems)
      const lineItemId = prop('lineItemId', lineItem)
      const handleSuccess = () => {
        trackRemoveCartEvent(
          lineItem,
          trackEvent,
          lineItems,
          null,
          lineItemIndex
        )
      }
      dispatch(IORemoveFromCart(lineItemId, undefined, handleSuccess))
    })

  const onClickProduct =
    (lineItems: readonly LineItem[]) => (lineItem: LineItem) => {
      const lineItemIndex = getPackageIndexInArray(lineItem, lineItems)
      trackSystemClick(lineItem, trackEvent, lineItems, null, lineItemIndex)
    }

  useEffect(() => {
    // TODO this function needs to be abstracted, exported, and have unit tests
    cart.forEach(_cart => {
      const cartItems = toItemList(
        getCartDetails(hiddenProductSkus)(_cart.lineItems)
      )(
        trackEvent,
        onUpdateQuantity,
        onRemoveProduct(_cart.lineItems),
        onClickProduct(_cart.lineItems)
      )
      const hasLineItems = !!length(_cart.lineItems)

      // set total qty of sensors with cameras
      setCameraSensorQuantity(cameraSensorQuantityInCart(_cart.lineItems))

      // Check if shield is in cart and shield quantity state
      hasLineItems &&
        setShieldQuantity(getQuantityInCart(_cart.lineItems)(SHIELD_SKU))
      // Check if scout is in cart and scout quantity state
      hasLineItems &&
        setScoutQuantity(getQuantityInCart(_cart.lineItems)(SCOUT_SKU))
      // Check if simplicam is in cart and simplicam quantity state
      hasLineItems &&
        setSimplicamQuantity(getQuantityInCart(_cart.lineItems)(SIMPLICAM_SKU))
      // Check if olympus is in cart and olympus quantity state
      hasLineItems &&
        setOlympusQuanity(getQuantityInCart(_cart.lineItems)(OLYMPUS_SKU))

      hasLineItems &&
        !_cart.isThereAnySecurity &&
        setProductWarningMessage(
          productMessageWithoutSystemData.chain(data =>
            safeFind(
              p => _cart.lineItems.some(l => equals(l.sku, p.productId)),
              data
            )
          )
        )
      setLineItems(cartItems)
      setTotalPrice(
        pipe(
          getCartSubTotal(siteLocale)(_cart),
          O.getOrElse(() => '')
        )
      )
      setSubTotalPrice(_cart.subTotal)

      setShowCartMonitoringPlan(
        liftFreeMonitoringSku
          .apTo(cart.toMaybe())
          .cata(
            F,
            shouldShowMonitoringPlanWidget => shouldShowMonitoringPlanWidget
          )
      )

      const brazeCartProducts = getLastCheckoutComponents(_cart, siteLocale)

      // Send Custom Event for eec.details when first page load.
      ifElse(
        _isMounted => {
          return !_isMounted && !isEmpty(_cart.lineItems) && !cartIsLoading
        },
        () => {
          trackLoadCartEvent(_cart.lineItems, trackEvent)
          brazeTrackCartDetails({
            cartId: get(CARTID) || '',
            cartTotal: formatDisplayPrice(_cart.subTotal).getOrElse(''),
            coupon: getCartDiscountCode(_cart),
            discountedCartTotal: pipe(
              getCartSubTotal(siteLocale)(_cart),
              O.getOrElse(() => '')
            ),
            products: brazeCartProducts
          })
          setMounted(true)
        },
        always(undefined)
      )(isMounted)
    })
  }, [cart, isMobile, dispatch, cartIsLoading])

  const shieldIsInCart: boolean = shieldQuantity > 0
  const scoutIsInCart: boolean = scoutQuantity > 0
  const simplicamIsInCart: boolean = simplicamQuantity > 0

  const props = useMemo(
    () =>
      pageContext && toContentfulCartDetails(data, shieldIsInCart, pageContext),
    [data, isMobile, shieldIsInCart]
  )

  /** if the user manually enters a promo code we want to remove the utm_code */
  const [enableUtmCode, setEnableUtmCode] = useState(true)
  useUtmCode(enableUtmCode)

  const toDiscountApplied = (
    discountAppliedText: string,
    discountCode?: string,
    discountAmount?: string
  ) => {
    return (
      discountAmount &&
      ({
        isHighlightedLineItem: true,
        itemName: discountAppliedText,
        price: discountAmount ?? '0',
        itemDescriptionContent: <p>{discountCode}</p>,
        isDiscount: true
      } as CartLineItemProps)
    )
  }

  const getCartDetailsProps = useCallback(
    (cart: ImmutableCart, cartIsUpdating: boolean): CartDetailsProps => {
      const standaloneProduct = prop('standaloneProduct', data) || []
      const couponCode = getCartDiscountCode(cart)
      const couponText = 'Coupon code applied'
      const discountApplied = toDiscountApplied(
        couponText,
        couponCode,
        pipe(
          getCartDiscountValue(cart, siteLocale),
          O.getOrElse(() => null)
        )
      )
      // This is messier than ideal, but this disables the quantity changers if the cart is currently updating
      const items: readonly CartLineItemProps[] = lineItem
        .map(_lineItems => {
          return _lineItems.map((lineItemProps, index) => {
            // TODO: fix type
            const parentId = defaultTo('')(
              prop('packageParentId', lineItemProps)
            )
            const previousItemSku = Maybe.fromUndefined(_lineItems[index - 1])
              .chain(safeProp('sku'))
              .getOrElse('')

            const product = Maybe.fromNull(
              productReference.find(
                product => product?.productId === lineItemProps?.sku
              )
            )

            const getQuantityProp = quantity => {
              const maxQuantity = productMaxQuantity.find(
                item => item && item.productId === prop('sku', lineItemProps)
              )
              return {
                ...quantity,
                disabled: cartIsUpdating,
                max: Maybe.fromNull(maxQuantity)
                  .chain(safeProp('maximumQuantity'))
                  .getOrElse(100)
              }
            }

            const description = product
              .chain(productInformation =>
                safeProp('productId', productInformation).map(sku => (
                  <CartLineItemDescription
                    key={sku}
                    onClick={(url: string) =>
                      navigateLink(url, parentId, previousItemSku)
                    }
                    productInformation={productInformation}
                    sku={sku}
                  />
                ))
              )
              .orNull()

            const subItemsLineItemSkus = (lineItemProps.subItems || []).map(
              subItem => subItem.subItemSku
            )
            const itemsNotInStock = componentsNotInStock(
              itemsProducts.map(products =>
                products.filter(
                  product =>
                    safeProp('sku', product).getOrElse('') ===
                    prop('sku', lineItemProps)
                )
              )
            )
            const subItemsProductsNotInStock = componentsNotInStock(
              subItemsProducts.map(products =>
                products.filter(product =>
                  subItemsLineItemSkus.includes(
                    safeProp('sku', product).getOrElse('')
                  )
                )
              )
            )
            const subItemsOOSMessage =
              subItemsProductsNotInStock.length > 0 &&
              renderCartLineOutOfStockMessage(subItemsProductsNotInStock)
            const itemsOOSMessage =
              itemsNotInStock.length > 0 &&
              renderCartLineOutOfStockMessage(itemsNotInStock)

            return {
              ...lineItemProps,
              itemDescriptionContent: description,
              outOfStockMessage: subItemsOOSMessage || itemsOOSMessage,
              // quantity changer props
              quantity: Maybe.fromNull(lineItemProps.quantity)
                .map(getQuantityProp)
                .orUndefined()
            }
          })
        })
        .orJust([])

      const selfMonitoringInCart: boolean = productIsInCart(
        items,
        'SSSLFCAM__7711192'
      )
      const sensorWarningMessage: ReactNode | undefined =
        renderSensorWarningMessage(
          productMaxQuantity,
          cameraSensorQuantity,
          shieldQuantity,
          items,
          siteLocale,
          selfMonitoringInCart
        )

      const isGtShieldMaxQuantity =
        (shieldIsInCart && shieldQuantity) > shieldMaxQuantity

      const isGtScoutMaxQuantity =
        (scoutIsInCart && scoutQuantity) > scoutMaxQuantity

      const isGtSimplicamMaxQuantity =
        (simplicamIsInCart && simplicamQuantity) > simplicamMaxQuantity

      const isGtOlympusMaxQuantity =
        (olympusQuantity && olympusQuantity) > olympusMaxQuantity

      // disable submit button until the customer updates his cart
      const disableCartSubmit: boolean =
        isGtShieldMaxQuantity ||
        isGtScoutMaxQuantity ||
        isGtSimplicamMaxQuantity ||
        isGtOlympusMaxQuantity

      const coreComponentsOosProps = toCoreComponentsOosProps(
        coreComponentsNotInStockList,
        cart.isThereAnySecurity,
        items
      )
      const freeShippingItem = getFreeShippingItem(
        shippingText,
        cart,
        siteLocale
      )
      const isHaveStandAloneProduct = items.find(item => {
        return standaloneProduct.find(
          product => product && product.sku === item.sku
        )
      })
      const isShowMessage = isHaveStandAloneProduct
        ? !isHaveStandAloneProduct
        : !cart.isThereAnySecurity
      isShowMessage
        ? setCookie('standAloneProduct', true)
        : setCookie('standAloneProduct', false)
      const isShowProductMessage = productWarningMessage.cata(
        always(isShowMessage),
        T
      )

      const cartHasSystem = cart.get('isThereAnySecurity', false)

      const rawTotalPrice = cart.get('totalPrice')

      const affirmPromoMessage = siteLocale === 'en-US' && (
        <AffirmPromoMessage
          affirmClient={affirmClient}
          onLearnMoreClick={optimizelyAffirmLearnMore}
          pageType="category"
          parentStyles={'font-normal text-xs text-right'}
          price={rawTotalPrice}
        />
      )

      const affirmMonthlyOption = siteLocale === 'en-US' && (
        <AffirmMonthlyOption
          affirmClient={affirmClient}
          discountedPrice={discountApplied ? rawTotalPrice : null}
          displaySubTotalText
          isAffirmMonthlyExperiment={isAffirmExperiment}
          subTotalPrice={subTotalPrice}
          useSystemTermMonths={cartHasSystem}
        />
      )

      const additionalItems = [discountApplied]
      const itemList =
        items.length > 0 &&
        items.concat(additionalItems.filter(lineItem => lineItem))

      return {
        ...props,
        ...coreComponentsOosProps,
        affirmPromoMessage,
        affirmMonthlyOption,
        cartCouponCode: couponCode,
        cartMonitoringPlan: cartMonitoringPlanContent(),
        disabledCartSubmit: disableCartSubmit,
        // Add the discount info and free shipping info when the cart is not empty dependent upon coupon variation.
        itemList: items.length > 0 ? itemList : items,
        freeShippingItem,
        quantityLimitMessage: sensorWarningMessage,
        getQuantityInCart: getQuantityInCart(cart.lineItems),
        showScoutWarning: renderScoutWarningMessage(
          cart.lineItems,
          cart.isThereAnySecurity
        ),
        productMaxQuantity,
        selfMonitoringInCart: selfMonitoringInCart,
        showWarning: isShowProductMessage,
        subTotal: totalPrice,
        ...(items.length > 0 &&
          cartHasSystem && {
            affirmCard: affirmCardContent()
          }),
        ...(items.length > 0 && {
          customerServiceCard: customerServiceCardContent(),
          tryItTestItCard: tryItTestItCardContent()
        })
      }
    },
    [
      data,
      lineItem,
      productMaxQuantity,
      cameraSensorQuantity,
      olympusMaxQuantity,
      shieldQuantity,
      siteLocale,
      shieldIsInCart,
      shieldMaxQuantity,
      coreComponentsNotInStockList,
      shippingText,
      props,
      cartMonitoringPlanContent,
      tryItTestItCardContent,
      totalPrice,
      productReference,
      productWarningMessage,
      affirmClient,
      optimizelyAffirmLearnMore,
      affirmCardContent,
      customerServiceCardContent,
      itemsProducts,
      subItemsProducts
    ]
  )

  const cartRetrievalErrorMessage = () => {
    const cartRetrievalErrorPhoneNumber: string =
      siteLocale === 'en-GB' ? '0800-920-2420' : '888-910-1458'

    return (
      <div>
        <h2>Something isn&apos;t right. Try reloading the page.</h2>
        <p>
          If the issue persists, please call {cartRetrievalErrorPhoneNumber} to
          complete your order.
        </p>
      </div>
    )
  }

  const handleSubmissionSuccess = useCallback(() => {
    setIsCouponError(false)
    setIsCouponSuccess(true)
    onApplyCouponTrack(trackEvent, promoCode, true)
    trackCartCouponApplied(promoCode, true)
    set('USER_DISCOUNT_CODE', promoCode)
    setTimeout(() => setIsCouponSuccess(false), 5000)
  }, [promoCode])

  const handleSubmissionError = useCallback(() => {
    setIsCouponError(true)
    setIsCouponSuccess(false)
    onApplyCouponTrack(trackEvent, promoCode, false)
    trackCartCouponApplied(promoCode, false)
    dispatch(clearCartError())
    setTimeout(() => setIsCouponError(false), 5000)
  }, [dispatch, promoCode])

  const debouncedUpdateDiscount = useMemo(
    () =>
      debounce(250, () =>
        dispatch(
          applyDiscount(
            promoCode,
            handleSubmissionError,
            handleSubmissionSuccess
          )
        )
      ),
    [dispatch, promoCode, handleSubmissionError, handleSubmissionSuccess]
  )

  const handleApplyCoupon = () => {
    setEnableUtmCode(false)
    promoCode === '' ? handleSubmissionError() : debouncedUpdateDiscount()
  }

  const discountProps: DiscountProps = {
    promoCode,
    isCouponError,
    isCouponSuccess,
    handleApplyCoupon,
    handleCouponChange: setPromoCode,
    isClose,
    setIsClose
  }

  const handleInvalidLocalCartId = (error: ServerError) => {
    error.status === 404 &&
      (() => {
        remove(LOCAL_STORAGE_CARTID)
        remove(getShippingInfoKey(LOCAL_STORAGE_CARTID))
        dispatch(clearCartError())
      })()
  }

  return (
    <Track>
      <>
        {cart.cata(
          () => {
            // Normally we would always use cart.cata(), but in this case we want to avoid showing the
            // loading view if the cart has a value and is just being updated, so the user doesn't see
            // a flicker when changing quantities
            return cart
              .map(_cart =>
                renderCart(siteLocale, {
                  ...getCartDetailsProps(_cart, true),
                  discountProps
                })
              )
              .orJust(
                <div className="flex h-full w-full flex-col content-center items-center justify-center">
                  <h2 className="font-arizona m-0 my-6 text-[35px] sm:text-[1px] md:text-[55px] lg:text-[55px]">
                    Loading your cart...
                  </h2>
                  <LoadingSpinner />
                </div>
              )
          },
          // Error state.
          (error: ServerError) => {
            handleInvalidLocalCartId(error)
            trackMetricEvent('cart-render-error', {
              error: error.statusText ?? ''
            })
            return (
              <BannerError height="responsive">
                {cartRetrievalErrorMessage()}
              </BannerError>
            )
          },
          // Cart empty state. CartDetails renders an empty cart message under the hood if there is no cart.
          () =>
            renderCart(siteLocale, {
              ...props,
              discountProps
            }),
          _cart =>
            renderCart(siteLocale, {
              ...getCartDetailsProps(_cart, false),
              discountProps
            })
        )}
      </>
    </Track>
  )
}
