import { graphql, useStaticQuery } from 'gatsby'

import plpPageSchema from './plpPageSchema'

export const useOdmonRefurbishedPLPQuery = () => {
  const staticQuery = useStaticQuery(graphql`
    query OdmonRefurbishedPlpTemplate {
      contentfulPackageListingPage(contentful_id: { eq: "4TD3O4ZXY4Yz83lEM86gEl" }) {
        ...plpPage
        ...plpPageVariations
      }
    }
  `)

  return plpPageSchema.parse(staticQuery.contentfulPackageListingPage)
}
