import { ODMON_SUBMIT_PLAN_ERROR_CODE } from '@ecomm/data-constants'
import { pipe } from 'fp-ts/lib/function'
import * as TE from 'fp-ts/TaskEither'

import { PlanSubmitResponse, sendPostPurchase } from './lib/sendPostPurchase'

const validationError = Error(ODMON_SUBMIT_PLAN_ERROR_CODE)

/**
 * https://simplisafe.atlassian.net/browse/ECP-10127
 * Submit selected plan
 */
export const submitPlan = (
  userId: string,
  email: string,
  plan: 'SSODMON247' | 'SSODMONON'
): TE.TaskEither<Error, true> =>
  pipe(
    sendPostPurchase(userId, email, plan),
    TE.chain<Error | Response, PlanSubmitResponse, true>(response =>
      response.planSelection !== '' ? TE.right(true) : TE.left(validationError)
    ),
    TE.mapLeft<Error | Response, Error>(() => validationError)
  )
