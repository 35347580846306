import { graphql, useStaticQuery } from 'gatsby'

import { productCardSchema } from '../components/ProductCard/schema'

const useOdmonCameraQuery = () => {
  const cameraQuery = useStaticQuery(graphql`
    #graphql
    query OdmonCameraQuery {
        odmonOlympusCamera: contentfulProduct(
            contentful_id: { eq: "1gfqsE26yZDiT7lwX1bjiO" }
        )  {
            ...productCard2
        }
    } 
`)
  const odmonCamera = cameraQuery.odmonOlympusCamera
  return productCardSchema.parse(odmonCamera)
}

export { useOdmonCameraQuery }
