import type { Address } from '@commercetools/platform-sdk'
import { useScript } from '@ecomm/checkout/payment-hooks'
import { ZuoraClient, ZuoraRenderParameters } from '@ecomm/data-zuora'
import { useTrackMetricEvent } from '@ecomm/tracking'
import { Locale } from '@ecomm/utils'
import { set as sessionStorageSet } from '@ecomm/utils'
import { PaymentMethodResponse } from '@simplisafe/ss-ecomm-data/simplisafe'
import {
  getZuoraMITConsentBrandList,
  getZuoraMITConsentReference,
  zuoraLocale,
  zuoraSdkUrl
} from '@simplisafe/ss-ecomm-data/thirdparty/zuora'
import * as O from 'fp-ts/lib/Option'
import { pipe } from 'fp-ts/lib/function'
import { useCallback, useEffect, useState } from 'react'

import {
  CardVerificationNote,
  RawProps
} from '../CardVerificationNote/CardVerificationNote'
import { formatCreditCardFields } from './utils'

// todo centralize -- also in usePayment and PaymentFormWrapper.types
export type PaymentState =
  | 'complete'
  | 'error'
  | 'loading'
  | 'processing'
  | 'ready'

type Props = {
  readonly billingAddress: O.Option<Address>
  readonly cardVerificationNote: O.Option<RawProps>
  readonly errorMessage: string
  readonly locale: Locale
  readonly onPaymentFormRender: () => void
  readonly paymentMethod: O.Option<PaymentMethodResponse>
  readonly paymentState: PaymentState
  readonly safeTechCollectorSdkUrl: string
  readonly zuoraClient: O.Option<ZuoraClient>
  readonly setIsSubmitted: (isSubmitted: boolean) => void
  readonly isSubmitted: boolean
}

export function ZuoraPaymentForm({
  billingAddress,
  cardVerificationNote,
  errorMessage,
  locale,
  onPaymentFormRender,
  paymentMethod,
  paymentState,
  safeTechCollectorSdkUrl,
  zuoraClient,
  setIsSubmitted
}: Props) {
  useScript(safeTechCollectorSdkUrl)
  const zuoraSdkStatus = useScript(zuoraSdkUrl)
  const [renderedPayment, setRenderedPayment] =
    useState<PaymentMethodResponse>()
  const trackMetricEvent = useTrackMetricEvent()

  const renderZuoraForm = useCallback(
    (zuoraSdk: ZuoraClient, paymentMethod: PaymentMethodResponse) => {
      const zuoraOptions: ZuoraRenderParameters = {
        ...paymentMethod,
        locale: zuoraLocale(locale),
        style: 'inline',
        submitEnabled: false
      }

      const onPaymentFormLoad = () => {
        zuoraSdk.setAgreement(
          'External',
          'Recurring',
          getZuoraMITConsentBrandList(locale),
          getZuoraMITConsentReference()
        )

        onPaymentFormRender()
      }

      const cardFields = O.toNullable(
        formatCreditCardFields(billingAddress, locale, paymentMethod)
      )

      zuoraSdk.setEventHandler<void>('onloadCallback', onPaymentFormLoad)

      setRenderedPayment(paymentMethod)

      cardFields &&
        zuoraSdk.renderWithErrorHandler(
          zuoraOptions,
          cardFields,
          () => null,
          // Validation callback logic which runs after a user clicks 'submit'
          // if a client-side error is found (e.g. 'Required Field Not Completed')
          (key: string, _: number | string, message: string) => {
            trackMetricEvent('zuora-validation-error', { key, message })
            setIsSubmitted(false)
            zuoraSdk.sendErrorMessageToHpm(key, message)
          }
        )

      zuoraSdk.runAfterRender(() => {
        zuoraSdk.sendErrorMessageToHpm('error', errorMessage)
      })
      setIsSubmitted(false)
      // set payment page id to session storage for the callback request
      sessionStorageSet('payment-page-id', zuoraOptions.id)
    },
    [billingAddress, errorMessage, locale, onPaymentFormRender]
  )

  useEffect(() => {
    // If the payment method exists and the Zuora Client exists, render the payment form unless it is already rendered.
    pipe(
      paymentMethod,
      O.map(paymentMethod => {
        pipe(
          zuoraClient,
          O.map(z => {
            paymentMethod !== renderedPayment &&
              renderZuoraForm(z, paymentMethod)
          })
        )
      })
    )
  }, [
    paymentMethod,
    renderZuoraForm,
    renderedPayment,
    setRenderedPayment,
    zuoraClient
  ])

  return zuoraSdkStatus === 'ready' ? (
    <div className="relative">
      <span className="mb-5 block font-bold">
        Enter your credit card details
      </span>
      {locale === 'en-GB' ? (
        <CardVerificationNote
          cardVerificationNote={cardVerificationNote}
          paymentState={paymentState}
        />
      ) : (
        <></>
      )}
      <div data-component="zuora-payment" id="zuora_payment" />
      {locale === 'en-US' ? (
        <span className="mt-4 block text-base italic text-[#0F2648]">
          Any new credit cards will be automatically saved to your account for
          future use
        </span>
      ) : null}
    </div>
  ) : (
    <></>
  )
}
