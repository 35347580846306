import { gatsbyImageSchema } from '@ecomm/contentful-schemas'
import { jsonSchema } from '@ecomm/micro-copy/src/lib/jsonSchema'
import {
  affirmBannerSchema,
  alarmMomentsSchema,
  guidedSystemBuilderSchema
} from '@ecomm/shared-components'
import { experimentTemplateSchema } from '@ecomm/shared-ninetailed'
import { type TypeOf, z } from '@simplisafe/ewok'

const ninetailedFeatureFlagDraftSchema = z.object({
  id: z.string(),
  title: z.string(),
  json: z.object({
    id: z.string(),
    tag: z.string()
  })
})
const experimentSchema = experimentTemplateSchema.extend({
  variants: z.array(ninetailedFeatureFlagDraftSchema)
})
export const ninetailedFeatureFlagSchema =
  ninetailedFeatureFlagDraftSchema.extend({
    nt_experiences: z.array(experimentSchema).default([])
  })

export const landerBelowTheFoldSchema = z.object({
  contentfulAffirmBanner: affirmBannerSchema,
  videoButtonAsset: gatsbyImageSchema,
  cameraBannerAsset: gatsbyImageSchema,
  expertReviewsAssets: z.object({
    nodes: gatsbyImageSchema.extend({ contentful_id: z.string() }).array()
  }),
  expertReviewsAdditionalAssets: z
    .object({
      nodes: gatsbyImageSchema.extend({ contentful_id: z.string() }).array()
    })
    .nullish(),
  appFeaturesCarouselAssets: z.object({
    nodes: gatsbyImageSchema.extend({ contentful_id: z.string() }).array()
  }),
  guaranteeAsset: gatsbyImageSchema,
  quoteWizardAsset: gatsbyImageSchema,
  quoteWizardAssetOdmon: gatsbyImageSchema.nullish(),
  quoteWizard: guidedSystemBuilderSchema,
  guaranteeCopy: jsonSchema.extend({ contentful_id: z.string() }),
  userReviewsData: jsonSchema.extend({ contentful_id: z.string() }),
  confidenceBar: jsonSchema.extend({ contentful_id: z.string() }),
  appWidget: jsonSchema.extend({ contentful_id: z.string() }),
  appWidgetOdmonVariant: jsonSchema
    .extend({ contentful_id: z.string() })
    .nullish(),
  userReviewsOdmonVariant: jsonSchema
    .extend({ contentful_id: z.string() })
    .nullish(),
  videoBanner: jsonSchema.extend({ contentful_id: z.string() }).nullish(),
  expertReviews: jsonSchema.extend({ contentful_id: z.string() }),
  expertReviewsAdditional: jsonSchema
    .extend({ contentful_id: z.string() })
    .nullish(),
  expertSectionAsset: gatsbyImageSchema,
  comparisonTable: jsonSchema,
  comparisonTableOdmonVariant: jsonSchema,
  odmonHPAlarmMoments: alarmMomentsSchema.nullish(),
  contentfulAlarmMoments: alarmMomentsSchema.nullish(),
  alarmMomentsExperiment: ninetailedFeatureFlagSchema.nullish(),
  hideVideoAndAffirmBannerExperiment: ninetailedFeatureFlagSchema.nullish()
})

export type LanderBelowTheFoldSchema = TypeOf<typeof landerBelowTheFoldSchema>
