import {
  ODMON_OVERNIGHT_MONITORING,
  ODMON_OVERNIGHT_MONITORING_60,
  ODMON_247_MONITORING,
  ODMON_247_MONITORING_60
} from '@ecomm/data-constants'
import { LineItem } from '@simplisafe/ss-ecomm-data/commercetools/cart'

/**
 * Determines if either of the Outdoor Monitoring Plan SKUs are in cart.
 */
export const hasOutdoorMonitoringInCart = (
  lineItems: readonly LineItem[]
): boolean => lineItems.some(lineItem => isOutdoorMonitoringPlan(lineItem.sku))

export const isOutdoorMonitoringPlan = (sku: string): boolean => {
  return (
    sku === ODMON_OVERNIGHT_MONITORING ||
    sku === ODMON_OVERNIGHT_MONITORING_60 ||
    sku === ODMON_247_MONITORING ||
    sku === ODMON_247_MONITORING_60
  )
}
