import { contentfulImageSchema } from '@ecomm/contentful/components'
import {
  contentfulRichTextSchema,
  gatsbyImageSchema,
  promoImageSchema
} from '@ecomm/contentful-schemas'
import { experimentTemplateSchema } from '@ecomm/shared-ninetailed'
import { z } from '@simplisafe/ewok'
import type { TypeOf } from 'zod'

import { buttonSchema } from '../Button/schema'
import { formQuerySchema } from '../Forms/PartnerWithUsFormSchema'
import { youtubeVideoSchema } from '../YoutubeVideo/schema'

const colors = z.enum([
  'brandPrimary',
  'complementaryBlue100',
  'neutralBlack',
  'neutralLight100',
  'neutralWhite',
  'neutralLight200',
  'complementaryGray100'
])

const guidedSystemBuilderSchemaDraft = z.object({
  __typename: z.literal('ContentfulQuoteWizard'),
  contentful_id: z.string().optional(),
  id: z.string(),
  jebbitUrl: z.string().optional(),
  type: z.enum([
    'embedded',
    'floating',
    'guarantee',
    'hero',
    'monitoring_component',
    'popup',
    'nav'
  ]),
  buttonText: z.string().nullish()
})

const qwExperimentSchema = experimentTemplateSchema.extend({
  variants: z.array(guidedSystemBuilderSchemaDraft).default([])
})

const guidedSystemBuilderSchema = guidedSystemBuilderSchemaDraft.extend({
  nt_experiences: z.array(qwExperimentSchema).default([])
})

export type GuidedSystemBuilderSchema = TypeOf<typeof guidedSystemBuilderSchema>

export const heroBannerColumnSchema = z.object({
  backgroundColor: colors,
  logo: gatsbyImageSchema.optional().nullable(),
  description: z.object({
    raw: z.string(),
    references: z.array(
      z.union([
        guidedSystemBuilderSchema.nullish(),
        buttonSchema.nullish(),
        youtubeVideoSchema.nullish()
      ])
    )
  }),
  descriptionMobile: contentfulRichTextSchema.optional(),
  image: contentfulImageSchema.nullish(),
  imageMobile: contentfulImageSchema.nullish(),
  imageTablet: contentfulImageSchema.nullish(),
  textColor: colors.nullish(),
  linkColor: colors.nullish(),
  textAlignment: z.enum(['left', 'center', 'right']).nullish(),
  contentVerticalAlignment: z.enum([
    'start',
    'center',
    'end',
    'stretch',
    'baseline'
  ]),
  hasPromoSticker: z.boolean(),
  form: formQuerySchema.nullish()
})

export const heroBannerDraftSchema = z.object({
  __typename: z.literal('ContentfulHeroBanner'),
  id: z.string(),
  content: heroBannerColumnSchema.array().default([]),
  floatingBadge: z.boolean(),
  floatingBadgeDiscount: z.string().nullish(),
  isFullWidth: z.boolean(),
  heightType: z.enum(['any', 'responsive']).default('responsive'),
  heroStickerImage: promoImageSchema.optional(),
  disclaimerText: z.string().nullish(),
  disclaimerTextAlignment: z.string().nullish(),
  roundedCorners: z.boolean()
})

const experimentSchema = experimentTemplateSchema.extend({
  variants: z.array(heroBannerDraftSchema)
})

export const heroBannerSchema = heroBannerDraftSchema.extend({
  nt_experiences: z.array(experimentSchema).default([])
})

export const types = {
  bgCenter: 'Background Media Centered Text',
  bgLeft: 'Background Media Text on the Left',
  bgRight: 'Background Media Text on the Right',
  leftMedia: 'Left Side Media',
  rightMedia: 'Right Side Media'
}

export type HeroBannerSchema = TypeOf<typeof heroBannerSchema> & {
  readonly className?: string
}
export type HeroBannerColumnSchema = TypeOf<typeof heroBannerColumnSchema>
