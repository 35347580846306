import type { CartLineItemProps } from '@ecomm/cart-line-items'
import {
  OLYMPUS_SKU,
  SCOUT_SKU,
  SHIELD_SKU,
  SIMPLICAM_SKU
} from '@ecomm/data-constants'
import { useMicroCopy } from '@ecomm/micro-copy'
import type { ContentfulProductCardAccessories } from 'apps/legacy/graphql'
import classNames from 'classnames'

import { Close } from '../icons/Close'
import { getMaxQuantityBySku } from '../utils'
import { CartLineItemName } from './CartLineItemName'
import { CartOutOfStock } from './CartOutOfStock'
import { CartQuantity } from './CartQuantity'
import { CartSubItem } from './CartSubItem'

type Props = {
  readonly item: CartLineItemProps
  readonly productMaxQuantity: readonly (ContentfulProductCardAccessories | null)[]
  readonly getQuantityInCart?: (sku: string) => number
}

const isCameraSku = (sku: string) =>
  [SCOUT_SKU, SIMPLICAM_SKU, SHIELD_SKU, OLYMPUS_SKU].includes(sku)

export function CartLineItem({
  item,
  productMaxQuantity,
  getQuantityInCart
}: Props) {
  const maxQuantity = getMaxQuantityBySku(item.sku, productMaxQuantity)
  const microCopy = useMicroCopy()
  const quantity = getQuantityInCart && getQuantityInCart(item.sku)

  // If the item is not a free item -> we are using the same component for free items so don't wanna duplicate the error message
  const shouldRenderQuantityWarningMessage =
    quantity > maxQuantity && !item.isFreeItem && isCameraSku(item.sku)

  const itemPrice = item.isIndeterminatePrice ? '--' : item.price

  const hideRemove = !item.onClickRemove || item.isImmutable

  return (
    <div
      className="mt-0 w-full py-2.5 pt-1.5 md:pb-0 md:pt-3 [&_a]:text-[#006fee]"
      data-component="CartLineItem"
      data-testid="CartLineItem"
    >
      {shouldRenderQuantityWarningMessage ? (
        <p className="text-sale m-0 pb-2.5 text-sm md:text-base">
          {microCopy['cart-camera-max-quantity-error'](maxQuantity)}
        </p>
      ) : null}

      <div
        className={classNames('flex justify-between md:justify-start', {
          'pl-8':
            !item.quantity &&
            !item.isHighlightedLineItem &&
            !item.isFreeShippingItem
        })}
      >
        <div className="flex items-end">
          {item.quantity ? (
            <CartQuantity
              quantity={item.quantity}
              trackQuantityChange={item.trackQuantityChange}
            />
          ) : null}
          {item.quantityText ? (
            <span className="text-sm md:text-lg" data-component="quantityText">
              {item.quantityText}
            </span>
          ) : null}
          <CartLineItemName item={item} />
        </div>
        <div className="bg-neutral-light-100 mx-4 mb-1.5 hidden h-px flex-1 self-end md:flex"></div>
        <div className="flex flex-col items-start md:flex-row md:justify-between">
          <div className="flex flex-row">
            <div
              className="flex flex-col md:flex-row"
              data-testid="cart-line-item-price"
            >
              <span
                className={classNames(
                  'text-base font-normal md:text-lg md:font-medium',
                  {
                    'line-through': item.isFreeItem,
                    'text-sale': item.isHighlightedLineItem
                  }
                )}
              >
                {itemPrice}
              </span>
              {item.isFreeItem ? (
                <span className="text-sale ml-5 text-right text-base font-normal md:ml-1 md:text-lg md:font-medium">
                  Free
                </span>
              ) : null}
            </div>
            <div
              aria-label="Remove Item"
              className={`cursor-pointer px-1 pl-2 ${
                hideRemove && 'invisible'
              }`}
              data-testid="remove-item-button"
              onClick={item.onClickRemove}
            >
              <Close color="#78746F" />
            </div>
          </div>
        </div>
      </div>
      {item.subItems && !!item.subItems.length ? (
        <CartSubItem subItems={item.subItems} />
      ) : null}
      {item?.itemDescriptionContent ? (
        <div
          className={classNames(
            'prose text-alert-100 md:prose-md prose-p:text-xs prose-p:text-[#78746F] md:prose-p:text-base flex items-center justify-start md:w-9/12',
            {
              'ml-[5.2rem]':
                !item.isHighlightedLineItem && !item.isFreeShippingItem,
              'md:ml-[5.6rem]':
                !item.isHighlightedLineItem && !item.isFreeShippingItem,
              'w-7/12': !item.isHighlightedLineItem,
              'w-12/12': item.isHighlightedLineItem
            }
          )}
        >
          {item.itemDescriptionContent}
        </div>
      ) : null}
      {item.outOfStockMessage ? (
        <CartOutOfStock
          hasSubItems={item.subItems ? !!item.subItems.length : null}
          message={item.outOfStockMessage}
        />
      ) : null}
    </div>
  )
}
