import { ContentfulRichText } from '@ecomm/shared-components'
import { Text as Typography } from '@ecomm/ss-react-components'
import Carousel from 'nuka-carousel'
import React from 'react'

import { Accolade } from './Accolade'

export type AccoladesProps = {
  readonly title: string
  readonly disclaimerText?: string
  readonly disclaimerLink?: string
}

type MonitoringAccoladesProps = {
  readonly title: string
  readonly monitoringAccoladesData: readonly AccoladesProps[]
}

export function MonitoringAccolades({
  data
}: {
  readonly data: MonitoringAccoladesProps
}) {
  const { monitoringAccoladesData, title } = data

  const accolades = monitoringAccoladesData.map((accolade, idx) => {
    return (
      <div key={idx}>
        <Accolade data={accolade} />
      </div>
    )
  })

  return (
    <>
      <Typography useTailwind>
        <div className="text-center text-4xl font-bold">
          <ContentfulRichText raw={title} />
        </div>
      </Typography>
      <div className="md:hidden">
        <Carousel
          cellAlign="left"
          cellSpacing={0}
          defaultControlsConfig={{
            pagingDotsContainerClassName: '!top-10',
            prevButtonClassName: 'hidden',
            nextButtonClassName: 'hidden'
          }}
          slidesToShow={1.4}
        >
          {accolades}
        </Carousel>
      </div>
      <div className="md:-mx-5 hidden justify-left md:flex flex-row overflow-x-auto lg:justify-center">
        {accolades}
      </div>
    </>
  )
}
