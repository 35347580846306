import { ODMON_247_MONITORING, ODMON_OVERNIGHT_MONITORING } from '.'

export const ODMON_VALIDATION_ERROR_CODE = 'ODMON-0000'
export const ODMON_SUBMIT_PLAN_ERROR_CODE = 'ODMON-0001'

export const ODMON_VALIDATION_ERROR_MSG = `We've hit a snag! We're having trouble processing your request. You may also be seeing this message if you have already placed an Outdoor Monitoring order. If you believe you have received this message in error, please reach out to pcs@simplisafe.com for assistance.`

export const FREE_CABLES_NAME = 'Outdoor Camera Power Cable'

export const CABLES_SKU = ''

export const MONITORING_PLAN_SKUS = [
  ODMON_OVERNIGHT_MONITORING,
  ODMON_247_MONITORING
]
