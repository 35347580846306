import {
  SecureHeaderProgressBarComponent,
  SiteWideMessagesContext
} from '@ecomm/checkout/layout'
import { useLocale } from '@ecomm/data-hooks'
import { ErrorBoundary } from '@ecomm/error-handling'
import { LiveChat } from '@ecomm/live-chat'
import { ApplyPromoCode, PageToaster } from '@ecomm/promotions-components'
import {
  CountryRedirectModal,
  Footer,
  SkipToContentLink
} from '@ecomm/shared-components'
import { PageBody, PageSection } from '@ecomm/ss-react-components'
import { useMediaQuery } from '@ecomm/ss-react-components'
import {
  devParams,
  TrackingProvider,
  useOptimizelyParams
} from '@ecomm/tracking'
import { type Locale, path } from '@simplisafe/ewok'
import { safePath, safeProp } from '@simplisafe/monda'
import { configureNewRelic } from '@simplisafe/ss-ecomm-data/thirdparty/errorLogging'
import { graphql, type PageProps } from 'gatsby'
import { useEffect } from 'react'
import { useTracking } from 'react-tracking'
import { BooleanParam, useQueryParam } from 'use-query-params'

import type {
  ContentfulFooter,
  ContentfulPaymentConfirmationContainer,
  ContentfulPaymentConfirmationPage,
  ContentfulReferAFriendPlaceholder,
  ContentfulSiteWideMessages,
  ContentfulSmallTextSection
} from '../../checkout.graphql'
import { checkoutFooterData } from '../components/CheckoutFooter'
import { PaymentConfirmationContainerComponent } from '../components/PaymentConfirmationContainerComponent'
import { ReferAFriendPlaceholderComponent } from '../components/ReferAFriendPlaceholderComponent'
import useAwinTracking from '../utils/useAwinTracking'
import useUtmContent from '../utils/useUtmContent'

export type PaymentConfirmationPageContext = {
  readonly id: string
  readonly locale: Locale
  readonly slug?: string
}

export type PaymentConfirmationPageQuery = {
  readonly contentfulSiteWideMessages?: Partial<ContentfulSiteWideMessages>
  readonly contentfulPaymentConfirmationPage?: Pick<
    ContentfulPaymentConfirmationPage,
    'metaTitle' | 'pageTitle' | 'site' | 'url'
  > & {
    readonly template?: {
      readonly hasLiveChat: boolean
      readonly liveChatAppId: string
      readonly defaultPageTitle: string
      readonly Footer?: Partial<ContentfulFooter>
      readonly countryRedirectModal?: Partial<ContentfulSmallTextSection>
    }
    readonly paymentConfirmationContainer?: Partial<ContentfulPaymentConfirmationContainer>
    readonly talkable?: Partial<ContentfulReferAFriendPlaceholder>
  }
}
export type PaymentConfirmationPageProps = PageProps<
  PaymentConfirmationPageQuery,
  PaymentConfirmationPageContext
>

function PaymentConfirmationPage({ data }: PaymentConfirmationPageProps) {
  const { trackEvent } = useTracking()
  const isTabletUp = useMediaQuery('TabletAndUp')
  const metaTitle =
    path(['contentfulPaymentConfirmationPage', 'metaTitle'], data) || ''

  const locale = useLocale()

  useEffect(() => {
    trackEvent({ isMobile: !isTabletUp })
  }, [isTabletUp, trackEvent])

  const [hidePopups] = useQueryParam(devParams.hidePopUps, BooleanParam)

  useUtmContent()
  useOptimizelyParams()
  useAwinTracking(locale === 'en-GB')

  useEffect(() => {
    // This sets up New Relic with our configuration from ecomm-data
    configureNewRelic()
    // Scroll to top on route change
    window && window.scrollTo(0, 0)
  }, [])

  const paymentConfirmationContainer: Partial<ContentfulPaymentConfirmationContainer> =
    safePath(
      ['contentfulPaymentConfirmationPage', 'paymentConfirmationContainer'],
      data
      // @ts-expect-error
    ).orJust({})

  const talkable: Partial<ContentfulReferAFriendPlaceholder> = safePath(
    ['contentfulPaymentConfirmationPage', 'talkable'],
    data
    // @ts-expect-error
  ).orJust({})

  const liveChatEnabled: boolean = safePath(
    ['contentfulPaymentConfirmationPage', 'template', 'hasLiveChat'],
    data
  ).orJust(false)

  const countryRedirectModal: Partial<ContentfulSmallTextSection> = safePath(
    ['contentfulPaymentConfirmationPage', 'template', 'countryRedirectModal'],
    data
    // @ts-expect-error
  ).orJust({})

  return (
    <ErrorBoundary>
      <TrackingProvider metaTitle={metaTitle}>
        <SiteWideMessagesContext.Provider
          // @ts-expect-error
          value={safeProp('contentfulSiteWideMessages', data).orJust({})}
        >
          <SkipToContentLink />
          <PageToaster />
          <ApplyPromoCode />
          <ErrorBoundary>
            <SecureHeaderProgressBarComponent />
          </ErrorBoundary>
          <ErrorBoundary>
            <PageBody>
              <PageSection>
                <PaymentConfirmationContainerComponent
                  data={paymentConfirmationContainer}
                />
                <ReferAFriendPlaceholderComponent data={talkable} />
              </PageSection>
            </PageBody>
          </ErrorBoundary>
          <ErrorBoundary>
            {
              <Footer
                data={checkoutFooterData}
                showCountrySelector={false}
                type="Slim"
              />
            }
          </ErrorBoundary>
          {liveChatEnabled ? <LiveChat /> : null}
          {!hidePopups && (
            <ErrorBoundary>
              {
                // @ts-expect-error
                <CountryRedirectModal data={countryRedirectModal} />
              }
            </ErrorBoundary>
          )}
        </SiteWideMessagesContext.Provider>
      </TrackingProvider>
    </ErrorBoundary>
  )
}

export const query = graphql`
  #graphql
  query PaymentConfirmationPage($id: String, $locale: String) {
    contentfulSiteWideMessages(node_locale: { eq: $locale }) {
      ...siteWideMessages
    }
    contentfulPaymentConfirmationPage(id: { eq: $id }) {
      pageTitle
      site
      url
      metaTitle
      paymentConfirmationContainer {
        ... on ContentfulPaymentConfirmationContainer {
          ...paymentConfirmationContainerFragment
        }
      }
      talkable {
        ... on ContentfulReferAFriendPlaceholder {
          ...mentionMeFragment
        }
      }
      template {
        defaultPageTitle
        hasLiveChat
        liveChatAppId
        Footer {
          ... on ContentfulFooter {
            ...contentfulFooter
          }
        }
        countryRedirectModal {
          ... on ContentfulSmallTextSection {
            ...checkoutSmallTextSectionFragment
          }
        }
      }
    }
  }
`
export default PaymentConfirmationPage
