import {
  ODMON_VALIDATION_ERROR_CODE,
  ODMON_VALIDATION_ERROR_MSG
} from '@ecomm/data-constants'
import { logError } from '@ecomm/error-handling'
import { useMicroCopy } from '@ecomm/micro-copy'
import { Cookie, useTrackingCookies } from '@ecomm/shared-cookies'
import { Cancel, ExclamationCircleSolid } from '@ecomm/shared-icons'
import {
  TrackMetricProps,
  TrackingData,
  params,
  setCustomBrazeCheckoutError,
  useOptimizelyTrackSiteEvents,
  useTrackMetricEvent
} from '@ecomm/tracking'
import {
  get as sessionStorageGet,
  set as sessionStorageSet
} from '@ecomm/utils'
import { localStorage } from '@simplisafe/ewok'
import { LOCAL_STORAGE_CARTID } from '@simplisafe/ss-ecomm-data/cart/actions'
import { useEffect, useState } from 'react'
import { useTracking } from 'react-tracking'
import { StringParam, useQueryParam } from 'use-query-params'
const { get } = localStorage

const PAYMENT_ERROR_COUNT = 'payment_error_view'

export function PaymentErrorMessage() {
  const [hidden, setShouldHide] = useState(false)
  const [shouldDisplay, setShouldDisplay] = useState(false)
  const [errorCount, setErrorCount] = useState(0)
  const [error] = useQueryParam(params.error, StringParam)
  const { Track, trackEvent } = useTracking({ appSection: 'paymentForm' })
  const [cookies] = useTrackingCookies()
  const trackMetricEvent = useTrackMetricEvent()
  const optimizelyTrackSiteEvents = useOptimizelyTrackSiteEvents()
  const microCopy = useMicroCopy()
  const [errorMessage, setErrorMessage] = useState('')

  useEffect(() => {
    const defaultMessage =
      errorCount > 1
        ? `An error occurred while attempting to place your order. To place your order over the phone call customer service at ${microCopy['payment-number']}.`
        : 'Please review your payment and billing information and try placing your order again.'

    setErrorMessage(
      error === ODMON_VALIDATION_ERROR_CODE
        ? ODMON_VALIDATION_ERROR_MSG
        : defaultMessage
    )
  }, [error, errorCount, microCopy])

  useEffect(() => {
    function handleError(
      error: string,
      trackEvent: (data: Partial<TrackingData>) => void,
      cookies: Cookie,
      trackMetricEvent: TrackMetricProps
    ) {
      const vid: string = cookies.vid || ''
      const drupalUid: string = cookies.DRUPAL_UID || ''
      const atAtToken: string = cookies.atat_v2 || ''
      const cartId: string = get(LOCAL_STORAGE_CARTID) || 'unknown'

      optimizelyTrackSiteEvents({ eventType: 'website_error' })
      logError(Error(`PAYMENT_FORM_ERROR: ${error}`), {
        atAtToken,
        cartId,
        drupalUid,
        vid
      })

      trackEvent({
        event: 'paymentFormError',
        transactionId: cartId
      })

      trackMetricEvent('payment-form-component-error', {
        error,
        attemptNum: sessionStorageGet(PAYMENT_ERROR_COUNT)
      })
    }

    error && handleError(error, trackEvent, cookies, trackMetricEvent)
  }, [error, trackEvent, cookies, trackMetricEvent, optimizelyTrackSiteEvents])

  useEffect(() => {
    const sendBrazeCheckoutError = () => {
      const timer = setTimeout(() => setCustomBrazeCheckoutError(), 5000)
      return () => clearTimeout(timer)
    }
    error && sendBrazeCheckoutError()

    // Display if the user is in variation OR is ODMON, the 'X' has not been clicked, and an error exists
    setShouldDisplay(Boolean(error && !hidden))
  }, [error, hidden])

  const updateViewCount = () => {
    const current = Number(sessionStorageGet(PAYMENT_ERROR_COUNT))
    sessionStorageSet(PAYMENT_ERROR_COUNT, String(current + 1))
    setErrorCount(current + 1)
  }

  useEffect(() => {
    // Track the number of page views to display a different error message
    // after 2+ error views
    shouldDisplay && updateViewCount()

    // Scroll to top
    shouldDisplay &&
      scrollTo({
        left: 0,
        top: 0,
        behavior: 'smooth'
      })
  }, [shouldDisplay])

  return shouldDisplay ? (
    <Track>
      <div
        className="my-2 rounded-md border border-solid border-red-800 bg-red-50 p-4 md:my-6"
        data-component="payment-form-error"
      >
        <div className="py-2">
          <span className="align-middle">
            <ExclamationCircleSolid
              className="text-red-800"
              height={18}
              width={18}
            />
          </span>
          <span className="p-2 font-bold text-red-800">
            Payment not processed
          </span>
          <span
            className="float-right hover:cursor-pointer"
            data-component="payment-error-exit"
            onClick={() => setShouldHide(true)}
          >
            <Cancel className="h-5 w-5 text-red-800" />
          </span>
        </div>
        <div>
          <span className="text-sm text-red-800">{errorMessage}</span>
        </div>
      </div>
    </Track>
  ) : (
    <></>
  )
}
