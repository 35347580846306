import { getRudderstack } from '@ecomm/shared-window'

/**
 * A function to track sensor quantity increase events to RudderStack
 */
export function trackSensorAdd(sensor?: string) {
  getRudderstack(r =>
    r.track('sensor_add_remove', { add_remove: 'add', sensor: sensor || '' })
  )
}

/**
 * A function to track sensor quantity decrease events to RudderStack
 */
export function trackSensorRemove(sensor?: string) {
  getRudderstack(r =>
    r.track('sensor_add_remove', { add_remove: 'remove', sensor: sensor || '' })
  )
}
