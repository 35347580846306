import {
  get as sessionStorageGet,
  remove as sessionStorageRemove,
  set as sessionStorageSet
} from '@ecomm/utils'
import * as O from 'fp-ts/lib/Option'
import { identity, pipe } from 'fp-ts/lib/function'
import {
  get as localStorageGet,
  remove as localStorageRemove,
  set as localStorageSet
} from 'local-storage'

const ODMON_TOKEN_KEY = 'odmonToken'
const ODMON_TOTAL_KEY = 'odmonTotal'
const ODMON_BETA_EXP_KEY = 'odmonBetaExp'

export const setOdmonToken = (token: O.Option<string>, force?: boolean) => {
  pipe(
    token,
    O.match(
      () => force && sessionStorageSet(ODMON_TOKEN_KEY, 'missing'),
      _token => sessionStorageSet(ODMON_TOKEN_KEY, _token)
    )
  )
}

const removeOdmonToken = () => sessionStorageRemove(ODMON_TOKEN_KEY)

export const getOdmonToken = () =>
  O.fromPredicate((s: string) => s !== '')(sessionStorageGet(ODMON_TOKEN_KEY))

export const isOdmonExperience = () => sessionStorageGet(ODMON_TOKEN_KEY) !== ''

export const setOdmonBetaExperience = () =>
  localStorageSet(ODMON_BETA_EXP_KEY, '1')
export const isOdmonBetaExperience = () => !!localStorageGet(ODMON_BETA_EXP_KEY)

export const setOdmonTotal = (total: number) => {
  pipe(
    isOdmonExperience(),
    O.guard,
    O.map(() => sessionStorageSet(ODMON_TOTAL_KEY, JSON.stringify(total)))
  )
}

const removeOdmonTotal = () => sessionStorageRemove(ODMON_TOTAL_KEY)

export const getOdmonTotal = () =>
  pipe(
    O.tryCatch(() => JSON.parse(sessionStorageGet(ODMON_TOTAL_KEY))),
    O.map(Number.parseFloat),
    O.filter(val => !Number.isNaN(val)),
    O.match(() => 0, identity)
  )

export const clearOdmonExperience = () => {
  removeOdmonToken()
  removeOdmonTotal()
}

export const clearOdmonBetaExperience = () =>
  localStorageRemove(ODMON_BETA_EXP_KEY)
