import { gatsbyImageSchema } from '@ecomm/contentful-schemas'
import {
  buttonSchema,
  guidedSystemBuilderSchema
} from '@ecomm/shared-components'
import { type TypeOf, z } from '@simplisafe/ewok'

export const media = gatsbyImageSchema.extend({ mimeType: z.string() })

const video = z.object({
  file: z
    .object({
      contentType: z.string(),
      fileName: z.string(),
      url: z.string()
    })
    .optional(),
  mimeType: z.string().default('video/mp4'),
  title: z.string().default('')
})

export const featureSectionSchemaMedia = z.union([media, video]).optional()
export const featureSectionSchemaReferences = z
  .union([buttonSchema, guidedSystemBuilderSchema])
  .array()
  .optional()

export const featureSectionSchema = z.object({
  __typename: z.literal('ContentfulFeatureSection'),
  body: z.object({
    raw: z.string(),
    references: featureSectionSchemaReferences
  }),
  media: featureSectionSchemaMedia.optional(),
  padding: z.enum(['small', 'medium', 'large']).optional(),
  type: z
    .enum([
      'Right Side Media',
      'Left Side Media',
      'Background Media Text on the Right',
      'Background Media Text on the Left',
      'Background Media Centered Text'
    ])
    .optional(),
  theme: z.enum(['Default', 'Gray', 'Brand Primary']).optional().nullish(),
  badge: z.string().default('').optional().nullish()
})

export const types = {
  bgCenter: 'Background Media Centered Text',
  bgLeft: 'Background Media Text on the Left',
  bgRight: 'Background Media Text on the Right',
  leftMedia: 'Left Side Media',
  rightMedia: 'Right Side Media'
}

export const themes = {
  default: 'Default',
  gray: 'Gray',
  primary: 'Brand Primary'
}

export type FeatureSectionSchema = TypeOf<typeof featureSectionSchema>
export type FeatureSectionMediaSchema = TypeOf<typeof featureSectionSchemaMedia>
export type FeatureSectionVideoType = TypeOf<typeof video>
export type FeatureSectionMediaType = TypeOf<typeof media>
