import type { ModalLocation } from '@ecomm/cdp-tracking'
import { InformationIcon } from '@ecomm/shared-icons'
import { Modal } from '@ecomm/ss-react-components'
import { useTrackHomepageComparisonChartModal } from '@ecomm/tracking'
import React, { useState } from 'react'

type InfoButtonModalProps = {
  readonly title: string
  readonly description: string | readonly string[]
  readonly modalLocation: ModalLocation
}

function InfoButtonIcon({
  title,
  description,
  modalLocation
}: InfoButtonModalProps) {
  const [isOpen, setIsOpen] = useState(false)
  const trackInfoModalOpen = useTrackHomepageComparisonChartModal(modalLocation)

  const handleInfoOnClick = () => {
    setIsOpen(true)
    trackInfoModalOpen()
  }

  return (
    <>
      <button
        aria-label="info"
        className="cursor-pointer border-none bg-transparent p-0 align-middle lg:px-1"
        onClick={handleInfoOnClick}
      >
        <InformationIcon className="text-complementary-blue-100 h-5 w-5 md:h-6 md:w-6" />
      </button>
      <Modal isOpen={isOpen} onRequestClose={() => setIsOpen(false)}>
        <div className="prose flex max-w-sm flex-col px-8 py-8 lg:max-w-xl">
          <h3 className="mb-4 font-bold" data-component="content_headline">
            {title}
          </h3>
          {typeof description === 'string' ? (
            <p
              className="text-base lg:text-lg"
              data-component="content_description"
            >
              {description}
            </p>
          ) : (
            description.map((desc, index) => (
              <p
                className="text-base lg:text-lg"
                data-component="content_description"
                key={index}
              >
                {desc}
              </p>
            ))
          )}
          <button
            className="rounded-base bg-btn-primary text-body-size hover:bg-btn-dark cursor-pointer border-none px-4 py-3 text-white"
            onClick={() => setIsOpen(false)}
          >
            Close
          </button>
        </div>
      </Modal>
    </>
  )
}

export default InfoButtonIcon
