import { useMicroCopy } from '@ecomm/micro-copy'
import { getValueFromPartnerCookie } from '@ecomm/shared-cookies'
import { useTrackingTermsConditionsClick } from '@ecomm/tracking'

export function TermsOfSale() {
  const trackTermsConditionsClick = useTrackingTermsConditionsClick()
  const microCopy = useMicroCopy()

  const onClickTermsConditions = (label: string) =>
    trackTermsConditionsClick(label)

  return (
    <div data-component="TermsOfSale">
      <div>
        <p className={'m-0 w-full text-xs text-[var(--neutral-dark-100)]'}>
          {microCopy['by-submitting-order-agree-simplisafe']}
          <a
            className={'pl-1 text-[var(--neutral-dark-100)]'}
            href="/terms-sale"
            onClick={() => onClickTermsConditions('terms of sale')}
            target="_blank"
          >
            {microCopy['terms-of-sale']}
          </a>
          ,
          <a
            className={'px-1 text-[var(--neutral-dark-100)]'}
            href="/terms-of-service"
            onClick={() => onClickTermsConditions('terms of service')}
            target="_blank"
          >
            {microCopy['terms-of-service']}
          </a>
          and
          <a
            className={'pl-1 text-[var(--neutral-dark-100)]'}
            href="/privacy-policy"
            onClick={() => onClickTermsConditions('privacy policy')}
            target="_blank"
          >
            {microCopy['privacy-policy']}
          </a>
          .
        </p>
        {getValueFromPartnerCookie('partnerName') === 'lightspeed' ? (
          <p
            className={'m-0 w-full pt-2 text-xs text-[var(--neutral-dark-100)]'}
          >
            By placing this order, I hereby acknowledge and agree that certain
            of my personal information (email address and service status) will
            be shared with Lightspeed Broadband.
          </p>
        ) : null}
      </div>
    </div>
  )
}
