import { useState } from 'react'

import { AlarmMomentsCta } from './AlarmMomentsCta'
import { AlarmMomentsHeader } from './AlarmMomentsHeader'
import { AlarmMomentSlide } from './AlarmMomentSlide'
import { DetailBubbles } from './DetailBubbles'
import { Illustration } from './Illustration'
import { LegalDisclaimer } from './LegalDisclaimer'
import type { AlarmMomentsSchema } from './schema'
import { useOnSelectAlarmMoment } from './useOnSelectAlarmMoment'

export type AlarmMomentsTabsHeaders = {
  readonly [name: string]: {
    readonly headline: string
    readonly description: string
  }
}

type AlarmMomentsProps = AlarmMomentsSchema & {
  readonly tabsHeaders?: AlarmMomentsTabsHeaders
}

export function AlarmMoments({
  tabs,
  cta,
  headline,
  description,
  tabsHeaders
}: AlarmMomentsProps) {
  const [currentSelected, setCurrentSelected] = useState(0)
  const currentCategory = tabs[currentSelected]
  const onSelectAlarmMoment = useOnSelectAlarmMoment()

  const handleSelectAlarmMoment = (carouselTab: number) => {
    onSelectAlarmMoment(carouselTab)
    setCurrentSelected(carouselTab)
  }

  const currentHeadline =
    tabsHeaders?.[tabs[currentSelected].name.toLowerCase()]?.headline ||
    headline
  const currentDescription =
    tabsHeaders?.[tabs[currentSelected].name.toLowerCase()]?.description ||
    description

  return (
    <div
      className="flex flex-col items-center justify-center"
      data-component="AlarmMoments"
    >
      <AlarmMomentsHeader
        description={currentDescription}
        headline={currentHeadline}
      />
      {tabs.length > 1 && (
        <div className="inset-x-1/2 ml-[-50vw] mr-[-50vw] flex w-screen max-w-[100vw] justify-center">
          <div className="flex items-center gap-2 overflow-x-auto p-2 pb-4">
            {tabs.map((tab, i) => (
              <AlarmMomentSlide
                isSelected={i === currentSelected}
                key={tab.name}
                onClick={() => handleSelectAlarmMoment(i)}
                text={tab.name}
              />
            ))}
          </div>
        </div>
      )}
      <Illustration image={currentCategory.image} />
      <DetailBubbles
        bubbles={currentCategory.details}
        theme={currentCategory.detailsBubblesTheme || 'dark'}
      />
      <LegalDisclaimer text={currentCategory.disclaimer} />
      {cta ? <AlarmMomentsCta text={cta.text} url={cta.url} /> : null}
    </div>
  )
}
