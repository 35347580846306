import { ContentfulImage } from '@ecomm/contentful/components'
import { ContentfulRichText } from '@ecomm/shared-components'
import { BigChevronDown } from '@ecomm/shared-icons'
import classNames from 'classnames'
import type React from 'react'

import type { StepperStepSchema } from '../ContentMapper/schema'
import { StepLine } from './StepLine'

export type Props = StepperStepSchema & {
  readonly stepNumber: number
  readonly isLastStep: boolean
  readonly onToggle: () => void
  readonly isOpen: boolean
  readonly onOpenNextStep: () => void
  readonly buttonText: string
  readonly currentStepRef: React.RefObject<HTMLDivElement>
}

export default function StepMobile({
  title,
  stepNumber,
  description,
  image,
  disclaimer,
  isLastStep,
  isOpen,
  onToggle,
  onOpenNextStep,
  buttonText,
  currentStepRef
}: Props) {
  return (
    <div
      className="grid w-full grid-cols-[32px,1fr] gap-2 lg:hidden"
      ref={currentStepRef}
    >
      <div>
        <StepLine
          isLastStep={isLastStep}
          isOpen={isOpen}
          stepNumber={stepNumber}
        />
      </div>

      <div className="border-b border-transparent border-b-[#B8B0A9] border-solid mb-8">
        <div className="flex flex-row" onClick={onToggle}>
          <h3 className="mb-4 text-xl grow">{title}</h3>
          <BigChevronDown
            className={classNames(
              'h-6 w-6 transition-transform duration-150 mt-2',
              {
                'rotate-180': isOpen
              }
            )}
          />
        </div>

        <div
          className={classNames(
            'transition-all duration-150 flex flex-col overflow-hidden',
            {
              'max-h-0': !isOpen,
              'max-h-full': isOpen
            }
          )}
        >
          <ContentfulImage
            {...image}
            className="bg-transparent"
            quality={100}
          />

          <div>
            <ContentfulRichText raw={description.raw} />
            <p className="text-[10px] md:text-xs">{disclaimer}</p>

            {!isLastStep && (
              <button
                className="btn btn-outlined btn-outlined-primary mt-2 mb-4 w-fit"
                onClick={onOpenNextStep}
                type="button"
              >
                {buttonText}
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
