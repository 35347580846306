import { landerBelowTheFoldSchema } from '@ecomm/lander'
import { graphql, useStaticQuery } from 'gatsby'

const homepageQuery = graphql`
  fragment homepageQWBaseFragment on ContentfulQuoteWizard {
    __typename
    id: contentful_id
    jebbitUrl
    type
    buttonText
  }

  fragment homepageQWExperienceFragment on ContentfulNinetailedExperience {
    id: contentful_id
    name: nt_name
    type: nt_type
    audience: nt_audience {
      id: contentful_id
      name: nt_name
    }
    config: nt_config {
      components {
        baseline {
          id
        }
        variants {
          id
          hidden
        }
      }
      traffic
      distribution
    }
    variants: nt_variants {
      ... on ContentfulQuoteWizard {
        ...homepageQWBaseFragment
      }
    }
  }
  query HomePageQuery {
    videoButtonAsset: contentfulAsset(contentful_id: { eq: "485NQEr4JUie3FE2IIlZ7B" }) {
      gatsbyImageData(placeholder: BLURRED)
      description
      title
    }
    contentfulAffirmBanner(contentful_id: { eq: "4VmV1UBXepTJFhRn8xKvni" }) {
      ...homeLandingAffirmBanner
    }
    quoteWizardAsset: contentfulAsset(contentful_id: { eq: "4mI9KaGvxh51fi1GYOSaVr" }) {
      gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
      description
    }
    quoteWizardAssetOdmon: contentfulAsset(contentful_id: { eq: "69DPXF1HEn2ut0vVVWFSUK" }) {
      gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
      description
    }
    cameraBannerAsset: contentfulAsset(contentful_id: { eq: "5mbs4LEkQgjIZpoFklL2ur" }) {
      gatsbyImageData(placeholder: BLURRED)
    }
    appFeaturesCarouselAssets: allContentfulAsset(
      filter: {
        contentful_id: {
          in: ["6rmX3DElVdPZn4Hw3JNqV9", "50wlqz44dQwAzOoxzmPO90", "38iheGuzSVg8kPQ1aokAnw", "ZYB8WxMtOe454lse5FG2D", "6LT6dvk157aAfFdKyeG2Kf", "4WmsMErxVXBFHEjlFnRcD1", "77eZKHHyg4KdFR2T2WhH90", "4Z794ao9hfY826K8Tt4FXz"]
        }
      }
    ) {
      nodes {
        contentful_id
        id
        title
        description
        gatsbyImageData(placeholder: BLURRED)
      }
    }
    expertReviewsAssets: allContentfulAsset(
      filter: {
        contentful_id: {
          in: ["6uVhRdfLw8y6L3x78SiCuM", "6Fk167pQyK44F3g51qYJ5U", "5X5OsfxAW6Fc2Zj3HZDlHC", "7eH4KhA049wM5ZTOOmWyWd", "3DTUGIquyV64OFfWua1sqK"]
        }
      }
    ) {
      nodes {
        contentful_id
        gatsbyImageData(width: 320)
        description
      }
    }
    expertReviewsAdditionalAssets: allContentfulAsset(filter: { contentful_id: { in: ["2zLbCUSDMAqzV7LqBPgxzE", "3Yk2CSFz84KprPq2t0oyU8"] } }) {
      nodes {
        contentful_id
        gatsbyImageData(width: 320)
        description
      }
    }
    guaranteeAsset: contentfulAsset(contentful_id: { eq: "5FRJNkZAaZL4mHk6vuMNVb" }) {
      gatsbyImageData(placeholder: BLURRED)
      description
    }
    quoteWizard: contentfulQuoteWizard(contentful_id: { eq: "3GT1zG3PE5djEurbN7NQmm" }) {
      ...homepageQWBaseFragment
      nt_experiences {
        ...homepageQWExperienceFragment
      }
    }
    guaranteeCopy: contentfulJson(contentful_id: { eq: "5bXaWtcoGF04HVmWb5Kuoe" }) {
      ...json
    }
    userReviewsData: contentfulJson(contentful_id: { eq: "5S5vhfcSNQqBcrZZhwVXWO" }) {
      ...json
    }
    confidenceBar: contentfulJson(contentful_id: { eq: "5ynnx5XFY9LYRUyZEkN98K" }) {
      ...json
    }
    appWidget: contentfulJson(contentful_id: { eq: "5cJhxXLT1ybrC7lgAQItHU" }) {
      ...json
    }
    userReviewsOdmonVariant: contentfulJson(contentful_id: { eq: "75YKq4uIe6BIqER0zH1fMN" }) {
      ...json
    }
    expertReviews: contentfulJson(contentful_id: { eq: "2143Uo0jib8jsfRyWv2DDQ" }) {
      ...json
    }
    expertReviewsAdditional: contentfulJson(contentful_id: { eq: "6IemG5492tUiqRu7HXa0B4" }) {
      ...json
    }
    appWidgetOdmonVariant: contentfulJson(contentful_id: { eq: "3aVetIx8QTn6wVgm1GNPpJ" }) {
      ...json
    }
    expertSectionAsset: contentfulAsset(contentful_id: { eq: "4l8DenaxSNBl1LH3mmDLMv" }) {
      gatsbyImageData(placeholder: BLURRED)
    }
    comparisonTable: contentfulJson(contentful_id: { eq: "1zTyewofJ6lKWySS451GBg" }) {
      ...json
    }
    comparisonTableOdmonVariant: contentfulJson(
      contentful_id: { eq: "18qXWQrIu5vaqsUScRFNeI" }
    ) {
      ...json
    }
    alarmMomentsExperiment: contentfulNinetailedFeatureFlag(
      contentful_id: { eq: "6iH6m7asb49baFD9Cnwi1N" }
    ) {
      ...homeLandingFeatureFlagBase
      nt_experiences {
        ...homeLandingFeatureFlagExperience
      }
    }
    odmonHPAlarmMoments: contentfulAlarmMoments(contentful_id: { eq: "29nudE6NrdopEG0nC3CntG" })    {
      ...homeLandingAlarmMoments
    }
    videoBanner: contentfulJson(contentful_id: { eq: "2MYM4NXbeu10yXiO50b5VD" }) {
      ...json
    }
    hideVideoAndAffirmBannerExperiment: contentfulNinetailedFeatureFlag(contentful_id: { eq: "2RzdpsTO6FqOdqspVlgM2G" }) {
      ...homeLandingFeatureFlagBase
      nt_experiences {
        ...homeLandingFeatureFlagExperience
      }
    }
  }
`

const useHomepageQuery = () => {
  return landerBelowTheFoldSchema.parse(useStaticQuery(homepageQuery))
}

export { useHomepageQuery }
