import type { RenderNode } from '@contentful/rich-text-react-renderer'
import { BLOCKS, INLINES } from '@contentful/rich-text-types'
import { ContentfulImage } from '@ecomm/contentful/components'
import { useLocale } from '@ecomm/data-hooks'
import {
  monthlyToLocalizedDailyPrice,
  usePriceContext
} from '@ecomm/data-price'
import { ContentfulRichText } from '@ecomm/shared-components'
import { useTrackOdmonPlanSelection } from '@ecomm/tracking'
import * as O from 'fp-ts/lib/Option'
import React from 'react'

import { AddPlanToCartButtonProduct } from '../AddPlanToCartButton'
import { LinkModal } from './LinkModal'
import type { OdmonMonitoringPlanCardSchema } from './schema'
import { WarningDisclaimer } from './WarningDisclaimer'

export type OdmonCardSchema = OdmonMonitoringPlanCardSchema & {
  readonly showAddToCartButton?: boolean
}

const customOptions: { readonly renderNode: RenderNode } = {
  renderNode: {
    [BLOCKS.HEADING_5]: (node, children) => {
      return <h5 className="my-0 text-base font-bold">{children}</h5>
    },
    [BLOCKS.HEADING_6]: (node, children) => {
      return <WarningDisclaimer>{children}</WarningDisclaimer>
    },
    [INLINES.EMBEDDED_ENTRY]: node => {
      const {
        data: {
          target: { description, headlineText, image }
        }
      } = node

      return (
        <LinkModal
          headline={headlineText}
          image={image}
          text={description.raw}
          trackLocation="cc"
        />
      )
    }
  }
}

function OdmonMonitoringPlanCard({
  title,
  subtitle,
  description,
  image,
  badge = '',
  cta,
  sku,
  showAddToCartButton = true
}: OdmonCardSchema) {
  const trackOdmonPlanSelection = useTrackOdmonPlanSelection()
  const locale = useLocale()
  const { getPrice } = usePriceContext()
  const priceText = O.match(
    () => null,
    (price: number) => {
      return monthlyToLocalizedDailyPrice(locale, price)
    }
  )(getPrice(sku))

  return (
    <div className="flex lg:w-1/3" data-testid="MonitoringPlanCard">
      <div className="flex grow flex-col rounded-2xl border border-solid border-neutral-200 bg-neutral-100">
        <div
          className={`flex items-center justify-center overflow-hidden rounded-t-2xl bg-[#0F2648] py-3 text-center text-2xl text-white md:text-3xl`}
        >
          {!!badge ? (
            <div className="inline-block" data-testid="MonitoringPlanCardBadge">
              <div
                className={`border-neutral-black bg-primary-100 mr-3 rounded-md border border-solid px-1 py-1 text-xs uppercase text-[#0F2648] md:py-0 md:text-lg`}
              >
                {badge}
              </div>
            </div>
          ) : null}
          <span>{title}</span>
        </div>
        <div className="flex grow flex-col px-2 pb-7">
          <div className="h-26 flex flex-row items-center gap-5 border-0 border-b border-solid border-neutral-200 px-10 py-6 md:py-4 text-center">
            {image ? (
              <ContentfulImage
                className="h-20 !w-20 bg-transparent"
                height={140}
                quality={100}
                width={140}
                {...image}
              />
            ) : null}
            <div className="prose-p:mb-0 flex h-20 w-full flex-col items-center justify-center font-bold text-base">
              <ContentfulRichText {...description} />
            </div>
          </div>
          <div className="last:prose-p:mb-0 grow border-0 border-b border-solid border-neutral-200 px-5 py-6 text-center text-base">
            <ContentfulRichText optionsCustom={customOptions} {...subtitle} />
          </div>
          <div className="item-center flex flex-row justify-between px-6 pt-4 align-middle">
            <div className="flex flex-row items-center align-middle">
              <div className="font-arizona text-complementary-red-100 text-4xl font-bold">
                {priceText}
              </div>
              <span className="ml-2">/day</span>
            </div>
            {showAddToCartButton ? (
              <AddPlanToCartButtonProduct
                buttonText={cta}
                className="mt-2 block !text-sm md:!text-lg"
                onClick={() => {
                  trackOdmonPlanSelection(title, 'cards')
                }}
                quantity={1}
                sku={sku}
              />
            ) : (
              <div className="text-neutral-500 prose-p:mb-0 text-right text-base">
                <p>Free trial.</p>
                <p>No contract.</p>
                <p>Cancel any time.</p>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default OdmonMonitoringPlanCard
