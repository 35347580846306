import React, { createRef, useRef, useState } from 'react'

import type { StepperSchema } from '../ContentMapper/schema'
import { Step } from './Step'
import StepMobile from './StepMobile'
import { TextWithSup } from './TextWithSup'

export function Stepper(props: StepperSchema) {
  const { badge, title, subtitle, steps } = props
  const [openSteps, setOpenSteps] = useState([0])
  const stepRefs = useRef<readonly React.RefObject<HTMLDivElement>[]>(
    Array.from({ length: steps.length }, () => createRef())
  )

  const handleToggleStep = (index: number) => {
    setOpenSteps(prev =>
      prev.includes(index)
        ? prev.filter(step => step !== index)
        : [...prev, index]
    )
  }

  const handleOpenNextStep = (index: number) => {
    const nextStepIndex = index + 1
    setOpenSteps(prev =>
      prev.includes(nextStepIndex) ? prev : [...prev, nextStepIndex]
    )

    stepRefs.current[nextStepIndex].current?.scrollIntoView({
      behavior: 'smooth',
      block: 'start'
    })
  }

  return (
    <div className="flex w-full max-w-8xl flex-col items-center lg:my-10 lg:px-8">
      <div>
        <div className="rounded-3xl bg-primary-100 px-4 pb-2 text-sm md:text-lg text-neutral-black font-bold">
          <TextWithSup classes="" text={badge || ''} />
        </div>
      </div>

      <h2 className="mb-4 md:mb-0 mt-6 text-center text-3xl md:text-6xl grow">
        <TextWithSup classes="text-xl md:text-4xl" text={title} />
      </h2>

      <p className="mt-4 lg:mt-6 mb-8 lg:mb-11 max-w-[840px] text-center">
        {subtitle}
      </p>

      <div className="w-full lg:m-11 lg:w-auto overflow-hidden">
        {steps.map((step, index) => (
          <React.Fragment key={`step-${index}`}>
            <Step
              key={`step-${index}`}
              {...step}
              isLastStep={index === steps.length - 1}
              stepNumber={index + 1}
            />

            <StepMobile
              key={`stepMobile-${index}`}
              {...step}
              buttonText={`Next: ${steps[index + 1]?.title}`}
              currentStepRef={stepRefs.current[index]}
              isLastStep={index === steps.length - 1}
              isOpen={openSteps.includes(index)}
              onOpenNextStep={() => handleOpenNextStep(index)}
              onToggle={() => handleToggleStep(index)}
              stepNumber={index + 1}
            />
          </React.Fragment>
        ))}
      </div>
    </div>
  )
}
