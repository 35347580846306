import {
  PRO_PREMIUM_MONITORING,
  PRO_PREMIUM_MONITORING_UK
} from '@ecomm/data-constants'
import { Modal } from '@ecomm/shared-components'

import { AddPlanToCartButtonProduct } from '../AddPlanToCartButton'
import type { ConfirmationModalSchema } from '../MonitoringPlanCard/schema'

type PlanConfirmationModalProps = {
  readonly isOpenModal: boolean
  readonly setIsOpenModal: (isOpen: boolean) => void
  readonly planConfirmationModal: ConfirmationModalSchema
  readonly locale: string
  readonly sku: string
  readonly name: string
}

// This is currently only being used by UK choose-monitoring pages.
export default function PlanConfirmationModal({
  isOpenModal,
  setIsOpenModal,
  planConfirmationModal,
  locale,
  sku,
  name
}: PlanConfirmationModalProps) {
  return (
    <Modal
      isOpen={isOpenModal}
      onRequestClose={() => setIsOpenModal(false)}
      size="small"
    >
      <div className="p-8">
        <h3 className="mb-3 mt-0 text-3xl	font-medium md:text-4xl">
          {planConfirmationModal.title}
        </h3>
        <p className="m-0 mb-4 text-base md:text-lg">
          {planConfirmationModal.description}
        </p>
        <div className="mt-8 flex flex-col items-center justify-around text-center md:flex-row">
          <AddPlanToCartButtonProduct
            buttonText={planConfirmationModal.recommendedText}
            className="h-16 w-full md:w-[16rem]"
            sku={
              locale === 'en-GB'
                ? PRO_PREMIUM_MONITORING_UK
                : PRO_PREMIUM_MONITORING
            }
          />
          <AddPlanToCartButtonProduct
            buttonText={`${name}, please`}
            className="h-16 w-full md:w-[16rem]"
            isSecondary={true}
            sku={sku}
          />
        </div>
      </div>
    </Modal>
  )
}
