import type { Options } from '@contentful/rich-text-react-renderer'
import { BLOCKS, INLINES } from '@contentful/rich-text-types'
import { useLocale } from '@ecomm/data-hooks'
import { useMicroCopy } from '@ecomm/micro-copy'
import { ContentfulRichText } from '@ecomm/shared-components'
import React, { useState } from 'react'

import ComparisonTable, { type TableProps } from '../ComparisonTable'
import MonitoringPlanCard from '../MonitoringPlanCard'
import MonitoringPlanModal from '../MonitoringPlanModal'
import type { MonitoringPlanModalSchema } from '../MonitoringPlanModal/schema'
import { categorizeRows, mapRows } from '../utils/table'
import type { ExpandableMonitoringSchema } from './schema'

const options: Options = {
  renderNode: {
    [BLOCKS.TABLE]: node => {
      const tableData: TableProps = {
        headRows: mapRows(node.content, BLOCKS.TABLE_HEADER_CELL),
        bodyRows: categorizeRows(mapRows(node.content, BLOCKS.TABLE_CELL), [
          'MONITORING SERVICES',
          'HARDWARE & CAMERAS',
          'MOBILE APP & SMART HOME',
          'WARRANTY, DISCOUNTS & PRICE'
        ])
      }
      return <ComparisonTable {...tableData} />
    },
    [INLINES.EMBEDDED_ENTRY]: node => {
      const data: MonitoringPlanModalSchema = node.data.target

      return <MonitoringPlanModal {...data} />
    }
  }
}

type ExpandableMonitoringProps = ExpandableMonitoringSchema & {
  readonly packageSku?: string
}

export function ExpandableMonitoring(data: ExpandableMonitoringProps) {
  const {
    headline,
    monitoringCards,
    comparisonTable,
    comparisonDisclaimer,
    planConfirmationModal,
    packageSku
  } = data
  const [allPlansVisible, setAllPlansVisible] = useState(false)
  const [comparisonTableVisible, setComparisonTableVisible] = useState(false)
  const microcopy = useMicroCopy()
  const locale = useLocale()

  return (
    <div className="prose md:prose-md lg:prose-lg flex w-full flex-col items-center gap-6 md:gap-8">
      {headline ? (
        <h2 className="mb-0 w-full text-center">{headline}</h2>
      ) : null}
      {monitoringCards.map((card, index) =>
        index === 0 || allPlansVisible ? (
          <MonitoringPlanCard
            {...card}
            key={`monitoring-card-${index}`}
            packageSku={packageSku}
            planConfirmationModal={planConfirmationModal}
          />
        ) : null
      )}
      {!allPlansVisible ? (
        <button
          className="btn btn-outlined btn-outlined-primary mx-auto"
          onClick={() => setAllPlansVisible(true)}
        >
          {microcopy['show-other-plans']}
        </button>
      ) : !comparisonTableVisible ? (
        <button
          className="btn btn-outlined btn-outlined-primary mx-auto"
          onClick={() => setComparisonTableVisible(true)}
        >
          {microcopy['compare-plans']}
        </button>
      ) : null}
      {comparisonTableVisible ? (
        <ContentfulRichText {...comparisonTable} optionsCustom={options} />
      ) : null}
      {comparisonDisclaimer && comparisonTableVisible && locale !== 'en-GB' ? (
        <div className="border-neutral-light-100 rounded-base border border-solid p-6 md:p-8">
          <ContentfulRichText
            {...comparisonDisclaimer}
            optionsCustom={options}
          />
        </div>
      ) : null}
    </div>
  )
}
