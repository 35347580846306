import React from 'react'

import type { AccoladesProps } from '..'

export function Accolade({ data }: { readonly data: AccoladesProps }) {
  const { title, disclaimerText, disclaimerLink } = data
  return (
    <>
      <div
        className="mx-2 lg:mx-5 flex min-h-[252px] min-w-[242px] lg:justify-center flex-col rounded-t-3xl text-2xl md:text-3xl md:max-w-[400px] lg:text-4xl"
        data-component="Accolade"
        style={{ backgroundColor: '#0f2648' }}
      >
        <div
          className="px-9 py-6 md:px-4 md:pt-6 md:pb-2 lg:p-9"
          data-component="AccoladeContent"
        >
          <div className="text-white font-bold">{title}</div>
          {disclaimerLink ? (
            <a
              className="text-xs text-white underline hover:no-underline"
              data-component="DisclaimerLink"
              href={disclaimerLink}
            >
              {disclaimerText}
            </a>
          ) : disclaimerText ? (
            <p className="mt-6 text-xs md:text-sm text-white">
              {disclaimerText}
            </p>
          ) : null}
        </div>
      </div>
      <div
        className="mx-2 lg:mx-5 h-[22px] rounded-b-3xl bg-[#FFAD29] min-w-[242px] md:max-w-[400px]"
        data-component="AccoladeBottomBanner"
      ></div>
    </>
  )
}
