import { useAffirmAPRExperiment } from '@ecomm/shared-hooks'
import { mapExperiences } from '@ecomm/shared-ninetailed'
import { useOdmonExperience } from '@ecomm/shared-ninetailed-odmon'
import React from 'react'

import type { AffirmFeatureFlagSchema } from '../../../experiments/AffirmAPR/schema'
import { useAffirmExperimentQuery } from '../../../experiments/AffirmAPR/useAffirmExperimentQuery'
import type { KeyValueFragment, ProductFragment } from '../types'
import DraftCartStaticList from '.'

interface Props {
  readonly baseProducts: readonly ProductFragment[]
  readonly otherItems: readonly KeyValueFragment[]
}

function AffirmDraftCartLineStaticWrapper({ baseProducts, otherItems }: Props) {
  const affirmFeatureFlagQueryData = useAffirmExperimentQuery()
  const mappedExperiences = mapExperiences<AffirmFeatureFlagSchema>(
    affirmFeatureFlagQueryData.nt_experiences
  )
  const isAffirmExperience = useAffirmAPRExperiment(mappedExperiences)
  const isOdmonExperiment = useOdmonExperience().isVariant

  return (
    <div
      data-component={
        isAffirmExperience
          ? 'AffirmDraftCartLineListVariant'
          : 'AffirmDraftCartLineListControl'
      }
    >
      <DraftCartStaticList
        baseProducts={baseProducts}
        isAffirmMonthlyExperiment={isAffirmExperience}
        isOdmonExperiment={isOdmonExperiment}
        otherItems={otherItems}
      />
    </div>
  )
}

export default AffirmDraftCartLineStaticWrapper
