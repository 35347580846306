import { handleZuoraResponse } from '@ecomm/checkout/payment-hooks'
import {
  formatCallbackRequest,
  getFailureParams,
  getResponseFrom,
  getSuccess,
  getSuccessParams
} from '@ecomm/data-zuora'
import { useMicroCopy } from '@ecomm/micro-copy'
import { LoadingSpinner } from '@ecomm/ss-react-components'
import { getSearchParams } from '@ecomm/utils'
import { useLocation } from '@reach/router'
import { exists, window } from 'browser-monads-ts'
import * as E from 'fp-ts/lib/Either'
import { pipe } from 'fp-ts/lib/function'
import { useEffect } from 'react'

function PaymentCallbackContainer() {
  const location = useLocation()
  const microCopy = useMicroCopy()
  const searchParams = getSearchParams(location)
  const responseFrom = getResponseFrom(searchParams)
  const success = getSuccess(searchParams)
  const successFn = () => {
    const successParams = getSuccessParams(searchParams)
    // FIXME move functions that directly access window to `@ecomm/shared-window`
    exists(window) &&
      window.parent &&
      // @ts-ignore
      window.parent.handleHPMSuccess &&
      // @ts-ignore
      window.parent.handleHPMSuccess(successParams)
  }

  const failureFn = () => {
    exists(window) &&
      pipe(
        searchParams,
        params => (params ? Object.keys(params).length === 0 : true),
        isEmpty => (isEmpty ? E.left(searchParams) : E.right(searchParams)),
        E.match(
          () => {
            window.location.href = '/error-404'
          },
          searchParams => {
            const failureParams = getFailureParams(searchParams)
            // FIXME move functions that directly access window to `@ecomm/shared-window`
            window.parent &&
              // @ts-ignore
              window.parent.handleHPMFailure &&
              // @ts-ignore
              window.parent.handleHPMFailure(failureParams)
          }
        )
      )
  }

  useEffect(() => {
    /**
     * Conditionally handle the response from the Zuora payment form callback
     * If the response is not from the Submit Page, then there was an error loading the form, so handle errors
     * If the response is from the Submit Page and it is unsuccessful, handle errors
     * If the response is from the Submit Page and it is successful, POST to payment decorator and handle based on response
     */
    pipe(
      responseFrom,
      rsp =>
        rsp !== 'Response_From_Submit_Page'
          ? E.left(success)
          : E.right(success),
      E.match(
        () => failureFn(),
        success =>
          success
            ? handleZuoraResponse(
                formatCallbackRequest(searchParams),
                successFn,
                failureFn
              )
            : failureFn()
      )
    )
  }, [])

  return (
    <div className="alignCenter" data-component="PaymentCallbackContainer">
      <h2>{microCopy['loading']}</h2>
      <LoadingSpinner />
    </div>
  )
}

export { PaymentCallbackContainer }
