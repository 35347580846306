import { useMicroCopy } from '@ecomm/micro-copy'
import { FloorPlanCarousel } from '@ecomm/shared-components'
import { useOdmonExperience } from '@ecomm/shared-ninetailed-odmon'
import { pipe } from 'fp-ts/lib/function'
import { useAtom } from 'jotai'
import React from 'react'
import { match } from 'ts-pattern'

import { itemQuantityAtom } from '../../atoms/draftCart/itemQuantityAtom'
import { useProductContext } from '../../contexts/ProductContext'
import useOdmonReplaceCameraItem from '../Package/hooks/useOdmonReplaceCameraItem'
import type { PackageType } from '../Package/schema'
import type { ProductCardFragment } from '../ProductCard/schema'
import type { CarouselItemFragment } from './schema'

type Props = {
  readonly products: readonly ProductCardFragment[]
  readonly packageName: string
  readonly packageType: PackageType
}

type ProductCardWithFloorPlan = ProductCardFragment & {
  readonly floorplanCarouselItem: CarouselItemFragment
}

function FloorPlan({ products, packageName, packageType }: Props) {
  const { getProducts } = useProductContext()

  const [itemMap] = useAtom(itemQuantityAtom)

  const dynamicListItems = useOdmonReplaceCameraItem(
    match(packageType)
      .with('Dynamic', () => pipe(itemMap.keySeq().toArray(), getProducts))
      .otherwise(() => []),
    useOdmonExperience().isVariant
  )

  const itemsWithDynamicListItems = products.concat(dynamicListItems)
  const items: readonly ProductCardWithFloorPlan[] = itemsWithDynamicListItems
    .filter(
      (product): product is ProductCardWithFloorPlan =>
        !!product.floorplanCarouselItem
    )
    .filter(
      (product, idx) =>
        itemsWithDynamicListItems.findIndex(
          item => item.sku === product.sku
        ) === idx
    )

  const microCopy = useMicroCopy()

  const name = packageType === 'Prebuilt' ? packageName : microCopy.simplisafe

  const title = `${name} ${microCopy['floor-plan-title']}`
  //@ts-expect-error
  return <FloorPlanCarousel items={items} title={title} />
}

export default FloorPlan
