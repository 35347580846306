import { useLocale } from '@ecomm/data-hooks'
import { PriceProvider, usePriceContext } from '@ecomm/data-price'
import { parseJSONDataFromContentful } from '@ecomm/scout'
import * as Icons from '@ecomm/shared-icons'
import { IconTypes } from '@ecomm/shared-icons'
import { match } from 'fp-ts/lib/Option'

import { useMonitoringQuery } from './query'
import { replaceTokensWithPrice } from '@ecomm/micro-copy'

type MonitoringProps = {
  readonly title: string
  readonly description: readonly string[]
  readonly icons: readonly {
    readonly icon: IconTypes
    readonly text: string
  }[]
  readonly relatedInformation: string
  readonly extraPriceText: string
  readonly productSKU: string
}

export function MonitoringWrapperUK() {
  const queryData = useMonitoringQuery()
  const copy: {
    readonly title: string
    readonly description: readonly string[]
    readonly icons: readonly {
      readonly icon: IconTypes
      readonly text: string
    }[]
    readonly relatedInformation: string
    readonly extraPriceText: string
    readonly productSKU: string
  } = parseJSONDataFromContentful(queryData, 'contentfulJson')
  const locale = useLocale()

  return (
    <PriceProvider locale={locale} skus={[copy.productSKU]}>
      <MonitoringUK {...copy} />
    </PriceProvider>
  )
}

function MonitoringUK(data: MonitoringProps) {
  const { getPrice } = usePriceContext()
  const locale = useLocale()
  const iconRowClass = ' grid-cols-3 md:grid-cols-4'

  const price = match(
    () => 0,
    (price: number) => price
  )(getPrice(data.productSKU))

  return (
    <div className="border-2 border-gray-300 border-solid lg:px-20 lg:py-16 md:p-8 p-4 rounded-[10px]">
      <div className="flex flex-col md:flex-row">
        <div className="flex w-full flex-col justify-between md:w-1/2 lg:w-7/12">
          <h2 className="font-default text-3xl font-medium md:text-4xl mt-0">
            {data.title}
          </h2>
          <h3 className="m-0">
            Included in your{' '}
            <span className="text-complementary-red-100">FREE</span> month
          </h3>
          <ul className="text-base text-gray-600 md:text-lg">
            {data.description.map((text, index: number) => (
              <li className="ml-0 items-center gap-2 py-2" key={index}>
                {text}
              </li>
            ))}
          </ul>
          <div>
            <p>{replaceTokensWithPrice(locale, data.extraPriceText, price)}</p>
            <p className="text-xs font-medium text-gray-600">
              {data.relatedInformation}
            </p>
          </div>
        </div>
        <div
          className={
            'grid w-full gap-y-6 text-center text-xs font-medium md:w-1/2 lg:w-5/12 lg:gap-x-8 lg:gap-y-12' +
            iconRowClass
          }
        >
          {data.icons.map((icon, i) => {
            const Icon = Icons[icon.icon]
            return (
              <div className="flex flex-col" key={i}>
                <Icon className="mx-auto h-14 w-14" />
                <p className="mx-auto w-auto text-gray-600">{icon.text}</p>
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}
